<div>
    <app-featured
    [background]="'assets/img/vacancy/vagas.jpeg'"
    [preTitle]="''"
    [title]="'vacancyTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-time-line
    [title] = "'vacancyTextOne' | translate"
    [timeLine] = "timeLine"
    [newTab] = "'false'"
    >
    </app-time-line>

    <app-contact></app-contact>
</div>