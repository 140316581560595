<div class="cta-area ptb-100" [style.background-image]="'url(' + background + ')'">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>{{preTitle}}</h4>
                <h2>{{title}}</h2>
                <p>{{subTitle}}</p>
                <a *ngIf="buttonOne" (click)="redirectToDetails(buttonOne.link)" class="btn btn-primary">{{buttonOne.text}}</a>
                <a *ngIf="buttonTwo" (click)="redirectToDetails(buttonTwo.link)" class="btn btn-primary view-work">{{buttonTwo.text}}</a>
            </div>
        </div>
    </div>
</div>