import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {

  tools = [
    {
      name: 'NODE JS',
      icon: '',
      translationKey: 'technologiesTextFive',
      img: 'assets/img/tools/nodejs-100x100.png'
    },
    {
      name: 'HTML',
      icon: '',
      translationKey: 'technologiesTextSix',
      img: 'assets/img/tools/html5-2.png'
    },
    {
      name: 'IONIC',
      icon: '',
      translationKey: 'technologiesTextSeven',
      img: 'assets/img/tools/ionic.png'
    },
    {
      name: 'JAVASCRIPT',
      icon: '',
      translationKey: 'technologiesTextEight',
      img: 'assets/img/tools/js.png'
    },
    {
      name: 'MYSQL',
      icon: '',
      translationKey: 'technologiesTextNine',
      img: 'assets/img/tools/mysql.png'
    },
    {
      name: 'CSS 3',
      icon: '',
      translationKey: 'technologiesTextTen',
      img: 'assets/img/tools/css.jpg'
    },
    {
      name: 'ANGULAR',
      icon: '',
      translationKey: 'technologiesTextEleven',
      img: 'assets/img/tools/angular.png'
    },
    {
      name: 'FIREBASE',
      icon: '',
      translationKey: 'technologiesTextTwelve',
      img: 'assets/img/tools/firebase.png'
    },
    {
      name: 'PHP',
      icon: '',
      translationKey: 'technologiesTextThirteen',
      img: 'assets/img/tools/php.png'
    },
    {
      name: 'POWER BI',
      icon: '',
      translationKey: 'technologiesTextFourteen',
      img: 'assets/img/tools/powerbi.png'
    },
    {
      name: 'APPLE',
      icon: '',
      translationKey: 'technologiesTextFifteen',
      img: 'assets/img/tools/apple.png'
    },
    {
      name: 'ANDROID',
      icon: '',
      translationKey: 'technologiesTextSixteen',
      img: 'assets/img/tools/android.svg'
    },
  ]

  constructor(private viewportScroller: ViewportScroller, private translate: TranslateService) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.loadTranslations();
  }

  loadTranslations() {
    const translationKeys = this.tools.map(t => t.translationKey);
  
    this.translate.get(translationKeys).subscribe((translations: any) => {
      this.tools.forEach(t => {
        if (translations[t.translationKey]) {
          translations[t.translationKey];
        }
      });
    });
  }
  

  onLanguageChange() {
    this.loadTranslations();
  }

}
