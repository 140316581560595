import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-text-left-img-right',
    templateUrl: './text-left-img-right.component.html',
    styleUrls: ['./text-left-img-right.component.scss']
})
export class TextLeftImgRightComponent implements OnInit {

    @Input() title;
    @Input() titleSpan;
    @Input() subTitle;
    @Input() img;
    @Input() list = [];
    @Input() preTitle;
    @Input() sectionTitle;
    @Input() sectionTitleSpan;
    @Input() sectionTubTitle;
    @Input() text;
    @Input() button: { text, link } = undefined;
    @Input() listTopic;
    @Input() listTopic2;
    @Input() listTopic3;
    @Input() topic;
    @Input() topic2;
    @Input() topic3;
    // @Input() newTab = 'true';

    constructor(private route: Router) { }

    ngOnInit() { }

    goToRoute(link) {

    }

    redirectToDetails(url: string) {
        window.open(url, '_self');
        // if(this.newTab == 'true'){
        //     window.open(url, '_blank');
        //   }else{
        //     this.route.navigate([url]);
        //   }
    }

}