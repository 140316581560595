<div id="technologies" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>{{ 'technologiesTextOne' | translate }}</h4>
            <h2>{{ 'technologiesTextTwo' | translate }} <span>{{ 'technologiesTextThree' | translate }}</span></h2>
            <p>{{ 'technologiesTextFour' | translate }}</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-4" *ngFor="let t of tools">
                <app-flip [img]="t.img" [name]="t.name" [text]="t.translationKey | translate"></app-flip>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>