<div>    
    <app-featured
    [background]="'assets/img/featured/network.jpeg'"
    [preTitle]="''"
    [title]="'casesTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-services
    [titulosCasosSucesso]="[
    { tituloHTwo: 'teamTextOne' | translate },
    ]"

    [casosSucesso]="[
    { imagem: 'assets/img/team/capa-totvs-3.jpg', titulo: 'PO-UI-TOTVS', link: 'po-ui-totvs' },
    { imagem: 'assets/img/team/vic-engenharia-400x400.jpg', titulo: 'Game Vic Engenharia', link: 'game-vic-engenharia' },
    { imagem: 'assets/img/team/clube-prime-box.jpg', titulo: 'Clube Prime Box', link: 'clube-prime-box' },
    { imagem: 'assets/img/team/no-bullying.png', titulo: 'No Bullying', link: 'no-bullying' },
    { imagem: 'assets/img/team/social-fit.jpg', titulo: 'Social Fit', link: 'social-fit' },
    { imagem: 'assets/img/team/vic-compliance.png', titulo: 'VIC Compliance', link: 'vic-compliance' },
    { imagem: 'assets/img/team/socienge-compliance.png', titulo: 'Socienge Compliance', link: 'socienge-compliance' },
    { imagem: 'assets/img/team/integridade-total.png', titulo: 'Integridade Total', link: 'integridade-total' },
    { imagem: 'assets/img/team/testis.png', titulo: 'Testis', link: 'testis' },
    { imagem: 'assets/img/team/beer-time-club.jpg', titulo: 'Beer Time Club', link: 'beer-time-club' },
    { imagem: 'assets/img/team/leilao-imoveis-brasil.png', titulo: 'Leilao Imoveis Brasil', link: 'leilao-imoveis-brasil' }
    ]"
    ></app-services>

    <app-contact></app-contact>
</div>