<div>
    <app-featured
    [background]="'assets/img/featured/laranja.jpg'"
    [preTitle]="''"
    [title]="'gameVicTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-text-right-img-left 
    [preTitle]="''"
    [title]="'GAME VIC'" [titleSpan]="'ENGENHARIA'"
    [subTitle]="''"
    [img]="'assets/img/game-vic-engenharia/vicc.png'"
    [sectionTitle]="''"
    [sectionTitleSpan]="''"
    [listTopic]="'Game VIC'"
    [topic]="'gameVicTextTwo' | translate"
    [listTopic2]="'gameVicTextThree' | translate"
    [topic2]="'gameVicTextFour' | translate"
    [listTopic3]="'gameVicTextFive' | translate"
    [topic3]="'gameVicTextSix' | translate"
    [listTopic4]="'gameVicTextSeven' | translate"
    [topic4]="'gameVicTextEight' | translate"
    [button]="">
    </app-text-right-img-left>

    <app-partner [list]="listPartners" [background]="'assets/img/bg2.jpg'">
    </app-partner>

    <app-feedback></app-feedback>

    <app-contact></app-contact>
</div>