<div>
    <app-featured
    [background]="'assets/img/featured/marrom.jpg'"
    [preTitle]="''"
    [title]="'primeBoxTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-text-right-img-left 
    [preTitle]="''"
    [title]="'BEER TIME'" [titleSpan]="'CLUB'"
    [subTitle]="''"
    [img]="'assets/img/beer-time-club/beer-time-club.jpg'"
    [sectionTitle]="''"
    [sectionTitleSpan]="''"
    [listTopic]="'Beer Time Club'"
    [topic]="'beerTimeClubTextOne' | translate"
    [listTopic2]="'primeBoxTextThree' | translate"
    [topic2]="'primeBoxTextFour' | translate"
    [listTopic3]="'primeBoxTextFive' | translate"
    [topic3]="'primeBoxTextSix' | translate"
    [listTopic4]="''"
    [topic4]="''"
    [button]="">
    </app-text-right-img-left>

    <app-partner [list]="listPartners" [background]="'assets/img/bg2.jpg'">
    </app-partner>

    <app-contact></app-contact>
</div>