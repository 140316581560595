import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saiba-tudo-sobre-javascript',
  templateUrl: './saiba-tudo-sobre-javascript.component.html',
  styleUrls: ['./saiba-tudo-sobre-javascript.component.scss']
})
export class SaibaTudoSobreJavascriptComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}