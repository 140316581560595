import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

interface CasoSucesso {
  imagem: string;
  titulo: string;
  subTitulo: string;
  link: string;
  showIcon: boolean;
}

interface tituloCasosSucesso {
  tituloHTwo: string;
  subTituloSpan: string
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  @Input() casosSucesso: CasoSucesso[] = [];
  @Input() titulosCasosSucesso: tituloCasosSucesso[] = [];
  showIcon: boolean = false;

  constructor(private viewportScroller: ViewportScroller) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
     this.casosSucesso.forEach(casoSucesso => casoSucesso.showIcon = false);
  }

  public toggleIcon(casoSucesso: CasoSucesso, show: boolean): void {
    casoSucesso.showIcon = show;
  }

}
