import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado',
  templateUrl: './gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado.component.html',
  styleUrls: ['./gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado.component.scss']
})
export class GamificacaoSaibaMaisSobreEssaTendenciaQueEstaDominandoOMercadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}