<div>
    <app-featured
    [background]="'assets/img/featured/azul.jpg'"
    [preTitle]="''"
    [title]="'primeBoxTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-text-right-img-left 
    [preTitle]="''"
    [title]="'TESTIS'" [titleSpan]="''"
    [subTitle]="''"
    [img]="'assets/img/testis/testis.png'"
    [sectionTitle]="''"
    [sectionTitleSpan]="''"
    [listTopic]="'Testis'"
    [topic]="'testisTextOne' | translate"
    [listTopic2]="'primeBoxTextThree' | translate"
    [topic2]="'primeBoxTextFour' | translate"
    [listTopic3]="'primeBoxTextFive' | translate"
    [topic3]="'primeBoxTextSix' | translate"
    [listTopic4]="''"
    [topic4]="''"
    [button]="">
    </app-text-right-img-left>

    <app-partner [list]="listPartners" [background]="'assets/img/bg2.jpg'">
    </app-partner>

    <app-contact></app-contact>
</div>