import { Component, OnInit, HostListener, Input, Renderer2 } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    isResponsiveMode: boolean = false;

    constructor(
        private viewportScroller: ViewportScroller,
        private route: Router,
        public translate: TranslateService,
        private renderer: Renderer2
    ) {
		translate.addLangs(['ES', 'PT-BR', 'EN-US']);
		translate.setDefaultLang('ES');
	 }
	 switchLang(lang: string) {
		this.translate.use(lang);
	 }

    @Input() logo = 'assets/img/logo_develop-valley/logob.png';
    @Input() menu: { link: string, translationKeyOne: string, isRoute: boolean, subItems?: { text: string, link: string }[], isOpen?: boolean, isHovering?: boolean }[] = [{
        link: 'home',
        translationKeyOne: 'Home',
        isRoute: false
    }]
    @HostListener('window:resize', ['$event'])
        onResize(event) {
        this.checkResponsiveMode();
    }


    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        try {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            if (scrollPosition >= 50) {
                this.isSticky = true;
                this.logo = 'assets/img/logo_develop-valley/logop.png'; // Altera o caminho da logo
            } else {
                this.isSticky = false;
                this.logo = 'assets/img/logo_develop-valley/logob.png'; // Volta para o caminho original da logo
            }
        } catch (error) {

        }

    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    public goToRoute(url: string): void {
        this.route.navigate([]).then(result => { window.open(url, '_blank'); }); //Abrir tela serviços em uma nova guia.
        // this.route.navigate([url]);  //Abrir tela serviços na mesma guia.
    }

    ngOnInit() { }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    handleSubItemClick(event: Event, subItem: any) {
        event.preventDefault();
        window.location.href = subItem.link;
    }

    // handleMouseEnter(menuItem: any) {
    //     menuItem.isOpen = true;
    // }

    // handleMouseLeave(menuItem: any) {
    //     setTimeout(() => {
    //         menuItem.isOpen = false;
    //     }, 3000);
    // }

    handleMouseEnter(menuItem: any) {
        if (menuItem.leaveTimeout) {
            clearTimeout(menuItem.leaveTimeout);
            menuItem.leaveTimeout = null;
        }
        menuItem.isOpen = true;
    }

    handleMouseLeave(menuItem: any) {
        menuItem.leaveTimeout = setTimeout(() => {
            menuItem.isOpen = false;
            menuItem.leaveTimeout = null;
        }, 300);
    }

    closeNavbar(menuItem: any) {
        if (menuItem.translationKeyOne !== 'navBarTextTwo') {
            const navbar = document.getElementById('navbarSupportedContent');
            if (navbar) {
                this.renderer.removeClass(navbar, 'show');
            }
            const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
            if (navbarToggler) {
                navbarToggler.click();
            }
        }
    }

    checkResponsiveMode() {
        this.isResponsiveMode = window.innerWidth < 768;
      }
}