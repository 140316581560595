import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens',
  templateUrl: './entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens.component.html',
  styleUrls: ['./entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens.component.scss']
})
export class EntendaOQueSaoPlataformasLowCodeEAsSuasVantagensComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
