<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>{{ 'contactTextOne' | translate }}</h4>
            <h2>{{ 'contactTextTwo' | translate }} <span>{{ 'contactTextThree' | translate }}</span> {{ 'contactTextFour' | translate }}</h2>
            <p>{{ 'contactTextFive' | translate }}</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <!-- <div class="contact-form">
                    <div class="container">
                        <iframe class="responsive-iframe" src="https://its.tolentinos.com/forms/wtl/4194e78df3b3ae2bddd47a779ea54d07" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> -->
                    
                    <!-- <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form> -->
                <!-- </div> -->
                <div class="contact-form">
                    <div class="container">
                        <div class="responsive-iframe-container">
                            <iframe class="responsive-iframe" src="https://its.tolentinos.com/forms/wtl/4194e78df3b3ae2bddd47a779ea54d07" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <!-- <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Location:</span>Exercisplan 4, 111 49 Stockholm, Sweden</li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:hello@xavro.com"><span>Email:</span>hello@xavro.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:1244122445515"><span>Call:</span>(+124)412-2445515</a></li>
                                <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>
                            </ul>
                        </div> -->
                        <img src="assets/img/logo_develop-valley/logopp.png" style="max-width: 270px;margin: auto 10%;margin-bottom: 50px;">
                        <p style="text-align: center;">{{ 'contactTextSix' | translate }}<br><br>
                            {{ 'contactTextSeven' | translate }} <br>
                            <b>{{ 'contactTextEight' | translate }}</b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>