<div>
    <app-featured
    [background]="'assets/img/our-contact/contato.jpeg'"
    [preTitle]="''"
    [title]="'ourContactTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-contact></app-contact>

    <app-summary-contacts></app-summary-contacts>

    <div class="container-fluid">
        <iframe class="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15894.899613428077!2d-43.93497753690266!3d-19.93717299267059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa6997b2028479b%3A0x731329c5f06daebe!2sDevelop%20Valley!5e0!3m2!1spt-BR!2ses!4v1696508845125!5m2!1spt-BR!2ses" height="600" style="border:0;width: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    
    
</div>