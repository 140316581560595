
<div class="main-banner item-bg-three">
    <div class="creative-banner-two"></div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container"><ng-particles
                [id]="id"
                [options]="particlesOptions"
                [particlesInit]="particlesInit"
                (particlesLoaded)="particlesLoaded($event)"
              ></ng-particles>
                <div class="main-banner-text">
                    <h3 style="color: white">{{ 'homeTextOne' | translate }}</h3>
                    <h1>{{ 'homeTextTwo' | translate }}&nbsp;
                        <ngx-typed-js
                            [strings]="['homeStringOne' | translate, 'homeStringTwo' | translate, 'homeStringThree' | translate, 'homeStringFour' | translate, 'homeStringFive' | translate, 'homeStringSix' | translate]"
                            [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p> {{ 'homeTextThree' | translate }}</p>
                    <a (click)="onClick('contact')" class="btn btn-primary">{{ 'homeTextFour' | translate }}</a>
                    <!-- <a (click)="onClick('work')" class="btn btn-primary view-work">Veja nosso portifólio</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-technologies></app-technologies>

<app-text-left-img-right 
    [title]="'homeTextFive' | translate" 
    [titleSpan]="'homeTextSix' | translate"
    [subTitle]="'homeTextSeven' | translate"
    [img]="'assets/img/our-services/mobilepng.png'"
    [sectionTitle]="'homeTextEight' | translate"
    [sectionTitleSpan]=" 'homeTextNine' | translate"
    [list]="['homeStringSeven' | translate,'homeStringEight' | translate,'homeStringNine' | translate]"
    [button]="{text:'homeTextTen' | translate,link:'/our-services'}">
</app-text-left-img-right>

<app-text-right-img-left 
    [preTitle]="'homeTextEleven' | translate"
    [title]="'homeTextTwelve' | translate " [titleSpan]="'homeTextThirteen' | translate"
    [subTitle]="'NOSSOS SERVIÇOS SÃO EXECUTADOS POR PROFISSIONAIS ALTAMENTE QUALIFICADOS'"
    [img]="'assets/img/our-services/PROGRAMAR.png'"
    [sectionTitle]="'DESENVOLVIMENTO DE SOFTWARE COM'"
    [sectionTitleSpan]="' AS MELHORES APLICAÇÕES'"
    [list]="['homeStringTen' | translate,'homeStringEleven' | translate,'homeStringTwelve' | translate,'homeStringThirteen' | translate,'homeStringFourteen' | translate,'homeStringFifteen' | translate]"
    [button]="{text:'homeTextFourteen' | translate,link:'/our-services'}">
</app-text-right-img-left>

<app-featured
    [preTitle]="'homeTextFifteen' | translate"
    [title]="'homeTextSixteen' | translate"
    [subTitle]="'homeTextSeventeen' | translate"
    [buttonOne]="{text:'homeTextEighteen' | translate,link:'/our-contact'}"
    >
</app-featured>

<app-partner [list]="listPartners" [background]="'assets/img/bg2.jpg'">
</app-partner>

<app-contact>
</app-contact>