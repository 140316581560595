import { Component } from '@angular/core';

@Component({
  selector: 'app-summary-contacts',
  templateUrl: './summary-contacts.component.html',
  styleUrls: ['./summary-contacts.component.scss']
})
export class SummaryContactsComponent {

}
