import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo',
  templateUrl: './arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo.component.html',
  styleUrls: ['./arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo.component.scss']
})
export class ArquiteturaDaInformacaoEntendaOConceitoEComoAplicaLoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}