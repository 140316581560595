import { Component } from '@angular/core';

@Component({
  selector: 'app-testis',
  templateUrl: './testis.component.html',
  styleUrls: ['./testis.component.scss']
})
export class TestisComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-1.png'
		},
    {
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-2.png'
		},
    {
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-3.png'
		},
    {
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-4.png'
		},
    {
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-5.png'
		},
    {
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-6.png'
		},
    {
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-7.png'
		},
    {
			link: 'testis',
			alt: 'imagesvisual',
			img: 'assets/img/testis/testis-8.png'
		}
	];
}