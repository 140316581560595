<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>{{'blogTextOne' | translate}}</h4>
            <h2>{{'blogTextTwo' | translate}}<span> {{'blogTextThree' | translate}}</span></h2>
            <p></p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span></span>
                        <h3><a routerLink="/entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens">Entenda o que são plataformas low-code e as suas vantagens</a></h3>
                        <p> O conceito de “Low Code” surgiu recentemente, aproximadamente em 2014, para dar nome a plataformas com interfaces que se baseiam no conceito de “arrastar e soltar”, também conhecido como GUI...</p>
                        <a routerLink="/entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg2">
                        <span></span>
                        <h3><a routerLink="/arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo">Arquitetura da Informação: Entenda o conceito e como aplicá-lo</a></h3>
                        <p> Com a chegada da internet, o acesso à informação ficou mais democrático, a partir disso passamos a ter acesso a uma infinidade de conteúdos. Isso ganha ainda mais intensidade quando...</p>
                        <a routerLink="/arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg3">
                        <span></span>
                        <h3><a routerLink="/linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas">Linguagem de programação: O que é e quais são mais utilizadas</a></h3>
                        <p> O conceito de linguagem de programação foi criado há um tempo com o objetivo de solucionar os prováveis problemas que surgem na área de tecnologia da informação, principalmente em aparelhos...</p>
                        <a routerLink="/linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span></span>
                        <h3><a routerLink="/o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas">O que faz um profissional formado em desenvolvimento de sistemas?</a></h3>
                        <p> O mercado de trabalho que envolve as áreas de Tecnologia da Informação, desenvolvimento de aplicativos e sistemas e programação é um dos que mais empregam no país, isso acontece devido...</p>
                        <a routerLink="/o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span></span>
                        <h3><a routerLink="/gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios">Gestão em TI: 4 ferramentas que vão te ajudar nos seus negócios</a></h3>
                        <p> A maioria das empresas que existem hoje possuem algum departamento para realizar as atividades voltadas à área de TI da corporação. Pequena, média ou de grande porte, essas empresas perceberam...</p>
                        <a routerLink="/gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg6">
                        <span></span>
                        <h3><a routerLink="/tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais">Tecnologia no Brasil: Conheça os principais eventos do segmento no país</a></h3>
                        <p> A tecnologia é um dos setores mais importantes que possuímos no cenário econômico no Brasil e no mundo. À medida que o acesso a internet se torna democrático e começa...</p>
                        <a routerLink="/tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg7">
                        <span></span>
                        <h3><a routerLink="/home-office-como-o-modelo-pode-ser-vantajoso-para-para-os-desenvolvedores-de-app">Home Office: Como o modelo pode ser vantajoso para para os desenvolvedores de app</a></h3>
                        <p> O home office, definitivamente, já se tornou uma realidade para a maioria das empresas que conseguem flexibilizar a modalidade de trabalho dos seus colaboradores. Além disso, o trabalho remoto possibilitou...</p>
                        <a routerLink="/home-office-como-o-modelo-pode-ser-vantajoso-para-para-os-desenvolvedores-de-app" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg8">
                        <span></span>
                        <h3><a routerLink="/desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas">Desenvolvedor, saiba como aumentar a sua produtividade com essas dicas</a></h3>
                        <p> Manter-se produtivo durante o dia pode ser um grande desafio para algumas pessoas, quando consideramos que a área de desenvolvedores de aplicativos possui um déficit de mão de obra, é...</p>
                        <a routerLink="/desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg9">
                        <span></span>
                        <h3><a routerLink="/saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso">Saiba quais métricas podem te ajudar a ter um app de sucesso</a></h3>
                        <p> Confira, neste texto, quais métricas podem te ajudar a ter um app de sucesso. Um aplicativo bem elaborado e que atenda as necessidades dos clientes é um destaque positivo para...</p>
                        <a routerLink="/saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg10">
                        <span></span>
                        <h3><a routerLink="/industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto">Indústria 4.0: Como os aplicativos contribuem para este projeto</a></h3>
                        <p> Que nós estamos vivendo uma revolução tecnológica, isso não é novidade. Cada dia é mais comum perceber a presença dos avanços da internet e suas contribuições para a melhoria da...</p>
                        <a routerLink="/industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg11">
                        <span></span>
                        <h3><a routerLink="/gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado">Gamificação: Saiba mais sobre essa tendência que está dominando o mercado</a></h3>
                        <p> Você já ouviu falar em gamificação? Provavelmente sim, se não escutou ainda, você deve ter utilizado esse método que vem ganhando popularidade nas empresas do país. O termo vem do...</p>
                        <a routerLink="/gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg12">
                        <span></span>
                        <h3><a routerLink="/saiba-tudo-sobre-javascript">Saiba tudo sobre JavaScript</a></h3>
                        <p> As linguagens de programação são as principais ferramentas de um desenvolvedor de sites de aplicativos.  E o JavaScript é uma das linguagens de programação que mais se destaca.  O JavaScript,...</p>
                        <a routerLink="/saiba-tudo-sobre-javascript" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg13">
                        <span></span>
                        <h3><a routerLink="/ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios">UX: O Que É E Como Ela Pode Trazer Benefícios Ao Seus Negócios</a></h3>
                        <p> Recentemente o termo UX se popularizou e passou a ser muito pesquisado entre as pessoas que têm interesse ou que trabalham com produtos digitais.</p>
                        <a routerLink="/ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg14">
                        <span></span>
                        <h3><a routerLink="/metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos">Metologia Ágil: A Forma Simples De Gerenciar Projetos Complexos</a></h3>
                        <p> O desenvolvimento de qualquer produto ou solução inovadora, passa por diversas etapas até o lançamento final e validação de resultados. E quando nesse momento de mensuração de resultados a equipe...</p>
                        <a routerLink="/metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>