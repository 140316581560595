import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios',
  templateUrl: './ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios.component.html',
  styleUrls: ['./ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios.component.scss']
})
export class UxOQueEEComoElaPodeTrazerBeneficiosAoSeusNegociosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}