import { Component } from '@angular/core';

@Component({
  selector: 'app-beer-time-club',
  templateUrl: './beer-time-club.component.html',
  styleUrls: ['./beer-time-club.component.scss']
})
export class BeerTimeClubComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'beer-time-club',
			alt: 'imagesvisual',
			img: 'assets/img/beer-time-club/beer-time-club-1.jpg'
		},
    {
			link: 'beer-time-club',
			alt: 'imagesvisual',
			img: 'assets/img/beer-time-club/beer-time-club-2.jpg'
		},
    {
			link: 'beer-time-club',
			alt: 'imagesvisual',
			img: 'assets/img/beer-time-club/beer-time-club-3.jpg'
		},
    {
			link: 'beer-time-club',
			alt: 'imagesvisual',
			img: 'assets/img/beer-time-club/beer-time-club-4.jpg'
		},
    {
			link: 'beer-time-club',
			alt: 'imagesvisual',
			img: 'assets/img/beer-time-club/beer-time-club-5.jpg'
		}
	];
}
