<div class="page-title-area item-bg-7">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>Home Office: Como O Modelo Pode Ser Vantajoso Para Para Os Desenvolvedores De App</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-details.jpg" alt="blog-details"> -->
                        <!-- <div class="date">20 <br> Dec</div> -->
                    </div>
                    <div class="article-text">
                        <!-- <ul class="category">
                            <li><a routerLink="/">IT</a></li>
                            <li><a routerLink="/">Mobile</a></li>
                            <li><a routerLink="/">Marketing</a></li>
                            <li><a routerLink="/">Design</a></li>
                            <li><a routerLink="/">Development</a></li>
                        </ul> -->
                        <!-- <h4 class="title">How to Be a Work From Home Professional</h4> -->
                        <p>O home office, definitivamente, já se tornou uma realidade para a maioria das empresas que conseguem flexibilizar a modalidade de trabalho dos seus colaboradores. Além disso, o trabalho remoto possibilitou, e inclusive aumentou, a criação de demandas para tirar projetos do papel, abrindo possibilidades de trabalho para desenvolvedores e programadores de aplicativos.</p>
                        <p>Com as pessoas passando mais tempo em casa, as empresas enxergaram a necessidade de criar alternativas viáveis para que as pessoas pudessem continuar consumindo os produtos e serviços comercializados por elas.</p>
                        <p>Quando consideramos o ano de 2020 e 2021, percebemos que há uma busca cada vez mais frequente por profissionais que possam atender a eminente demanda de desenvolvimento dos serviços digitais de diversas empresas, sejam de empresas brasileiras ou do exterior.</p>
                        <p>O principal alvo dessa busca são pessoas que estejam dispostas a desenvolver as suas atividades e, claro, tenham qualificação para que sejam empregadas, seja na modalidade CLT ou no modelo freelancer. Se observarmos os dados da modalidade de contratação, 60% dos participantes de uma pesquisa desenvolvida pela Brasscom (Associação das Empresas de Tecnologia da Informação e Comunicação e de Tecnologias Digitais), têm preferência pelo contrato de “carteira assinada”.</p>
                        <p>Dos profissionais que participaram desse levantamento, 86,5% preferem trabalhar no modelo do home office, mas quais são as vantagens dessa modalidade de trabalho? Nós vamos te contar.</p>
                        <br>
                        <h4>As vantagens de trabalhar de casa</h4>
                        <br>
                        <p>Desenvolver as atividades laborais do conforto do lar pode ser vantajoso tanto para o desenvolvedor como para aquele que gera a oportunidade de trabalho.</p>
                        <p>Entre as principais vantagens apontadas por quem trabalha e promove o trabalho remoto estão o aumento da qualidade de vida dos colaboradores, uma diminuição considerável de custos, tanto para empresas como para colaboradores e um aumento significativo na produtividade, o que é vantajoso para quem trabalha e para quem contrata.</p>
                        <br>
                        <h5>Aumento da produtividade</h5>
                        <br>
                        <p>Esse é um dos pontos mais debatidos, é comum que empresários cogitem a possibilidade do trabalho desenvolvido pelos colaboradores perca qualidade e produtividade, já que o acompanhamento não está sendo realizado de modo presencial.</p>
                        <p>Reza a lenda que os colaboradores que desenvolvem os trabalhos de casa tendem a ser menos disciplinados, mas há pesquisas, como a da Universidade de Stanford, que conclui que o trabalho remoto aumentou a produtividade de algumas empresas em 13%. Se considerarmos o Brasil, 56% dos trabalhadores que estão em home office se consideram mais produtivos, aponta esta pesquisa.</p>
                        <br>
                        <h5>Diminuição dos custos</h5>
                        <br>
                        <p>Quão econômico pode ser trabalhar de casa? Quando se trabalha de casa, o empregado reduz custos, assim como o colaborador, já que não há contribuição com os gastos de transporte, além da ajuda de custo que costuma ser paga para quem trabalha na modalidade remota. Também é possível conciliar duas atividades, caso seja a vontade do desenvolvedor.</p>
                        <br>
                        <h5>Redução dos casos de estresse</h5>
                        <br>
                        <p>Com menos pessoas precisando enfrentar o trânsito caótico das cidades brasileiras, ao menos duas vezes por dia, foi possível identificar uma redução no número de desenvolvedores estressados. Além disso, pesquisas apontam que 82% dos trabalhadores que desempenham suas atividades em home office afirmam estar menos estressados em algum nível.</p>
                        <br>
                        <h5>Menos incidência de doenças</h5>
                        <br>
                        <p>Sabemos que muitas empresas adotaram o modelo home office devido a pandemia do Covid-19, essa foi uma das estratégias para diminuir a taxa de contágio em cidades de todo o mundo. Pesquisas também apontam que os colaboradores já foram para escritórios com algum sintoma de doença.</p>
                        <br>
                        <h5>Melhora na sua alimentação e na qualidade do sono</h5>
                        <br>
                        <P>Pesquisas mostram que 42% das pessoas que trabalham em home office adotaram uma alimentação mais saudável quando comparado com o período em que trabalhavam em prédios comerciais e escritórios. 45% dizem que estão dormindo melhor. Outra pesquisa aponta que pessoas que não precisam se deslocar diariamente para o local de trabalho correm menos risco de sofrer com taxas elevadas de colesterol e obesidade.</P>
                        <br>
                        <h5>Tendência no Brasil</h5>
                        <br>
                        <p>Como o home office tende a ser uma realidade no Brasil, como aponta a Sociedade Brasileira de Teletrabalho e Teleatividades, o número de pessoas nessa modalidade aumentou 22%, quando consideramos as empresas, 45% delas adotam o home office, 15% querem implementar o modelo de trabalho.</p>

                        <!-- <blockquote class="blockquote">
                            <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate.</p>
                        </blockquote> -->
                        <div class="author-share">
                            <div class="row align-items-center">
                                <!-- <div class="col-lg-6 col-md-6">
                                    <div class="article-author">
                                        <div class="author-img">
                                            <img src="assets/img/client-avatar1.jpg" alt="client">
                                        </div>
                                        <div class="author-title">
                                            <h4>John Smith</h4>
                                            <span>Back-End Developer</span>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-12 col-md-6">
                                    <ul class="social-share">
                                        <li><a href="#" tooltip="Share on Facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Instagram"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Vimeo"><i class="fab fa-vimeo-v"></i></a></li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a routerLink="/blog-details"><i class="fas fa-chevron-left"></i> Prev Post</a>
                    </div>
                    <div class="controls-right">
                        <a routerLink="/blog-details">Next Post <i class="fas fa-chevron-right"></i></a>
                    </div>
                </div> -->
                <!-- <div class="post-comments">
                    <h3 class="title mt-0">Comments</h3>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>David Warner</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Eva Amith</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>James Andy</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment mb-0">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Audy Kanna</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="leave-a-reply">
                    <h3 class="title mt-0">Leave a Reply</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" name="name" placeholder="Full Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" class="form-control" name="email" placeholder="E-Mail">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

