<div>
    <h2> ​ ​ ​ ​ ​ ​ ​ ​</h2>
    <h2>{{title}}</h2>
    <h2> ​ ​ ​ ​ ​ ​ ​ ​</h2>
</div>

<div class="timeline">
    <div *ngFor='let entry of timeLine; let i = index'>
        <div class="container" [ngClass]="{ left: i%2 !== 0, right: i%2 == 0 }">
            <div class="content" style="text-align: center;">
                <h5>{{entry.year}}</h5>
                <h2>​</h2>
                <h4>{{entry.translationKeyOne | translate}}</h4>
                <h2>​</h2>
                <p>{{entry.translationKeyTwo | translate}}</p>
                <h2>​</h2>
                <img *ngIf="entry.image" [src]="entry.image" alt="Imagem {{ entry.year }}">
                <h2>​</h2>
                <button  *ngIf="entry.translationKeyThree" class="timeline-button" (click)="redirectToDetails(entry.url)">{{entry.translationKeyThree | translate}}</button>              
            </div>
        </div>
    </div>
</div>
