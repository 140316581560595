import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-office-como-o-modelo-pode-ser-vantajoso-para-para-os-desenvolvedores-de-app',
  templateUrl: './home-office-como-o-modelo.component.html',
  styleUrls: ['./home-office-como-o-modelo.component.scss']
})
export class HomeOfficeComoOModeloPodeSerVantajosoParaParaOsDesenvolvedoresDeAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
