<div class="partner-area ptb-70 bg-fffdfd" [style.background-image]="'url(' + background + ')'">
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide *ngFor="let l of list">
                    <div class="partner-item">
                        <a [href]="l.link" target="_self"><img [src]="l.img" [alt]="l.alt"></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>