import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import {
	MoveDirection,
	ClickMode,
	HoverMode,
	OutMode,
	Engine,
	Container,
  } from "tsparticles-engine";

  import { loadSlim } from "tsparticles-slim";

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent {

	listPartners: { link: string, img: string, alt: string }[] = [

		{
			link: '',
			alt: 'bancodobrasil',
			img: 'assets/img/partner/bancodobrasil.png'
		},
		{
			link: '',
			alt: 'caixaeconomica',
			img: 'assets/img/partner/caixaeconomica.png'
		},
		{
			link: '',
			alt: 'pti',
			img: 'assets/img/partner/pti.png'
		},
		{
			link: '',
			alt: 'classone',
			img: 'assets/img/partner/classone.png'
		},
		{
			link: '',
			alt: 'totvs',
			img: 'assets/img/partner/totvs.png'
		},
		{
			link: '',
			alt: 'colegiobatista',
			img: 'assets/img/partner/colegiobatista.png'
		},
		{
			link: '',
			alt: 'vic-engenharia',
			img: 'assets/img/partner/vic-engenharia.png'
		},
		{
			link: '',
			alt: 'brxretail',
			img: 'assets/img/partner/brxretail.png'
		},
		{
			link: '',
			alt: 'TechBridge',
			img: 'assets/img/partner/techbridge.png'
		},
		{
			link: '',
			alt: 'fumec',
			img: 'assets/img/partner/fumec.png'
		},
		{
			link: '',
			alt: 'mapa',
			img: 'assets/img/partner/MAPA.png'
		}
		,
		{
			link: '',
			alt: 'transformingIdeas',
			img: 'assets/img/partner/transformingIdeas.png'
		},
		{
			link: '',
			alt: 'culturinvest',
			img: 'assets/img/partner/culturinvest.png'
		},
		{
			link: '',
			alt: 'mackenzie',
			img: 'assets/img/partner/mackenzie.png'
		},
		{
			link: '',
			alt: 'nhd',
			img: 'assets/img/partner/nhd.png'
		},
		{
			link: '',
			alt: 'acosantaclara',
			img: 'assets/img/partner/acosantaclara.png'
		},
		{
			link: '',
			alt: 'imagesvisual',
			img: 'assets/img/partner/imagesvisual.png'
		},
		{
			link: '',
			alt: 'Prefisan',
			img: 'assets/img/partner/prefisan.png'
		},
		{
			link: '',
			alt: 'Socienge',
			img: 'assets/img/partner/socienge.png'
		},
	];

	id = "tsparticles";
	particlesOptions = {
		background: {
		//   color: {
		// 	value: "#00000000",
		//   },
		},
		fpsLimit: 120,
		interactivity: {
		  events: {
			onClick: {
			  enable: true,
			  mode: ClickMode.push,
			},
			onHover: {
			  enable: true,
			  mode: HoverMode.repulse,
			},
			resize: true,
		  },
		  modes: {
			push: {
			  quantity: 4,
			},
			repulse: {
			  distance: 200,
			  duration: 0.4,
			},
		  },
		},
		particles: {
		  color: {
			value: "#ffffff",
		  },
		  links: {
			color: "#ffffff",
			distance: 150,
			enable: true,
			opacity: 0.5,
			width: 1,
		  },
		  move: {
			direction: MoveDirection.none,
			enable: true,
			outModes: {
			  default: OutMode.bounce,
			},
			random: false,
			speed: 6,
			straight: false,
		  },
		  number: {
			density: {
			  enable: true,
			  area: 800,
			},
			value: 160,
		  },
		  opacity: {
			value: 0.5,
		  },
		  shape: {
			type: "circle",
		  },
		  size: {
			value: { min: 1, max: 6 },
		  },
		},
		detectRetina: true,
	  };


	constructor(private viewportScroller: ViewportScroller) { }

	public onClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	particlesLoaded(container: Container): void {
		console.log(container);
	  }
	
	  async particlesInit(engine: Engine): Promise<void> {
		console.log(engine);
	
		// Starting from 1.19.0 you can add custom presets or shape here, using the current tsParticles instance (main)
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		//await loadFull(engine);
		await loadSlim(engine);
	  }

}
