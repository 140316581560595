import { Component } from '@angular/core';

@Component({
  selector: 'app-no-bullying',
  templateUrl: './no-bullying.component.html',
  styleUrls: ['./no-bullying.component.scss']
})
export class NoBullyingComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'no-bullying',
			alt: 'imagesvisual',
			img: 'assets/img/no-bullying/nobullying-1.png'
		},
    {
			link: 'no-bullying',
			alt: 'imagesvisual',
			img: 'assets/img/no-bullying/nobullying-2.png'
		},
    {
			link: 'no-bullying',
			alt: 'imagesvisual',
			img: 'assets/img/no-bullying/nobullying-3.png'
		},
    {
			link: 'no-bullying',
			alt: 'imagesvisual',
			img: 'assets/img/no-bullying/nobullying-4.png'
		},
    {
			link: 'no-bullying',
			alt: 'imagesvisual',
			img: 'assets/img/no-bullying/nobullying-5.png'
		},
    {
			link: 'no-bullying',
			alt: 'imagesvisual',
			img: 'assets/img/no-bullying/nobullying-6.png'
		},
    {
			link: 'no-bullying',
			alt: 'imagesvisual',
			img: 'assets/img/no-bullying/nobullying-7.png'
		}
	];
}