import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

export interface CardData {
  imageId: string;
  state: "default" | "flipped" | "matched";
}

@Component({
  selector: 'app-flip',
  templateUrl: './flip.component.html',
  styleUrls: ['./flip.component.scss'],
  animations: [
    trigger("cardFlip", [
      state(
        "default",
        style({
          transform: "none"
        })
      ),
      state(
        "flipped",
        style({
          transform: "rotateY(180deg)"
        })
      ),
      state(
        "matched",
        style({
          visibility: "false",
          transform: "scale(0.05)",
          opacity: 0
        })
      ),
      transition("default => flipped", [animate("400ms")]),
      transition("flipped => default", [animate("400ms")]),
      transition("* => matched", [animate("400ms")])
    ])
  ]
})
export class FlipComponent {

  timer = 0;

  data: CardData = {
    imageId: "pDGNBK9A0sk",
    state: "default"
  };

  flipped = false;

  @Input() name;
  @Input() text;
  @Input() img;

  mouseout() {
    if (this.timer === 0) {
      this.data.state = "default";
    }
    setTimeout(() => {
      this.timer = 0;
    }, this.timer);
  }

  mouseover() {
    if (this.timer === 0) {
      this.data.state = "flipped";
      this.timer = 200;
    }

    setTimeout(() => {
      this.timer = 0;
    }, this.timer);
  }

}
