import { Component } from '@angular/core';

@Component({
  selector: 'app-socienge-compliance',
  templateUrl: './socienge-compliance.component.html',
  styleUrls: ['./socienge-compliance.component.scss']
})
export class SociengeComplianceComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'socienge-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/socienge-compliance/socienge-compliance-1.png'
		},
    {
			link: 'socienge-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/socienge-compliance/socienge-compliance-2.png'
		},
    {
			link: 'socienge-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/socienge-compliance/socienge-compliance-3.png'
		},
    {
			link: 'socienge-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/socienge-compliance/socienge-compliance-4.png'
		},
    {
			link: 'socienge-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/socienge-compliance/socienge-compliance-5.png'
		},
    {
			link: 'socienge-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/socienge-compliance/socienge-compliance-6.png'
		},
    {
			link: 'socienge-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/socienge-compliance/socienge-compliance-7.png'
		}
	];
}