<div>
    <app-featured
    [background]="'assets/img/featured/silhuetas.jpeg'"
    [preTitle]="''"
    [title]="'aboutTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-featured-two
    [background]="''" 
    [preTitle]="''"
    [title]="'aboutTextTwo' | translate"
    [subTitle]="'aboutTextThree' | translate"
    [buttonOne]=""
    >
    </app-featured-two>

    <app-featured
    [background]="'assets/img/featured/azul.jpg'"
    [preTitle]="''"
    [title]="'aboutTextFour' | translate"
    [subTitle]="'aboutTextFive' | translate"
    [buttonOne]=""
    >
    </app-featured>

    <app-time-line
    [title] = "'aboutTextSix' | translate"
    [timeLine] = "timeLine"
    >
    </app-time-line>

    <app-contact></app-contact>

</div>