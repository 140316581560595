import { Component, Input, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-text-right-img-left',
  templateUrl: './text-right-img-left.component.html',
  styleUrls: ['./text-right-img-left.component.scss']
})
export class TextRightImgLeftComponent implements OnInit {

  @Input() title;
  @Input() titleSpan;
  @Input() subTitle;
  @Input() img;
  @Input() list = [];
  @Input() preTitle;
  @Input() sectionTitle;
  @Input() sectionTitleSpan;
  @Input() sectionTubTitle;
  @Input() text;
  @Input() button: { text, link } = undefined;
  @Input() listTopic;
  @Input() listTopic2;
  @Input() listTopic3;
  @Input() listTopic4;
  @Input() topic;
  @Input() topic2;
  @Input() topic3;
  @Input() topic4;

  goToRoute(link) {

  }

  constructor(private viewportScroller: ViewportScroller) { }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

  redirectToDetails(url: string) {
    window.open(url, '_self');
  }

}
