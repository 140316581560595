<div>    
    <app-featured
    [background]="'assets/img/featured/blog.jpeg'"
    [preTitle]="''"
    [title]="'BLOG'"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <!-- <app-blog></app-blog> -->

    <app-services
    [titulosCasosSucesso]="[
    { tituloHTwo: 'blogTextTwo' | translate, subTituloSpan: 'blogTextThree' | translate  },
    ]"

    [casosSucesso]="[
    { imagem: 'assets/img/blog/blog1.png', titulo: 'Entenda o que são plataformas low-code e as suas vantagens', subTitulo:'O conceito de “Low Code” surgiu recentemente, aproximadamente em 2014, para dar nome a plataformas com interfaces que se baseiam no conceito de “arrastar e soltar”, também conhecido como GUI...', link: 'entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens' },
    { imagem: 'assets/img/blog/blog2.png', titulo: 'Arquitetura da Informação: Entenda o conceito e como aplicá-lo', subTitulo:'Com a chegada da internet, o acesso à informação ficou mais democrático, a partir disso passamos a ter acesso a uma infinidade de conteúdos. Isso ganha ainda mais intensidade quando...', link: 'arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo' },
    { imagem: 'assets/img/blog/blog3.png', titulo: 'Linguagem de programação: O que é e quais são mais utilizadas', subTitulo:'O conceito de linguagem de programação foi criado há um tempo com o objetivo de solucionar os prováveis problemas que surgem na área de tecnologia da informação, principalmente em aparelhos...', link: 'linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas' },
    { imagem: 'assets/img/blog/blog4.png', titulo: 'O que faz um profissional formado em desenvolvimento de sistemas?', subTitulo:'O mercado de trabalho que envolve as áreas de Tecnologia da Informação, desenvolvimento de aplicativos e sistemas e programação é um dos que mais empregam no país, isso acontece devido...', link: 'o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas' },
    { imagem: 'assets/img/blog/blog5.png', titulo: 'Gestão em TI: 4 ferramentas que vão te ajudar nos seus negócios', subTitulo:'A maioria das empresas que existem hoje possuem algum departamento para realizar as atividades voltadas à área de TI da corporação. Pequena, média ou de grande porte, essas empresas perceberam...', link: 'gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios' },
    { imagem: 'assets/img/blog/blog6.png', titulo: 'Tecnologia no Brasil: Conheça os principais eventos do segmento no país', subTitulo:'A tecnologia é um dos setores mais importantes que possuímos no cenário econômico no Brasil e no mundo. À medida que o acesso a internet se torna democrático e começa...', link: 'tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais' },
    { imagem: 'assets/img/blog/blog7.png', titulo: 'Home Office: Como o modelo pode ser vantajoso para para os desenvolvedores de app', subTitulo:'O home office, definitivamente, já se tornou uma realidade para a maioria das empresas que conseguem flexibilizar a modalidade de trabalho dos seus colaboradores. Além disso, o trabalho remoto possibilitou...', link: 'home-office-como-o-modelo-pode-ser-vantajoso-para-para-os-desenvolvedores-de-app' },
    { imagem: 'assets/img/blog/blog8.png', titulo: 'Desenvolvedor, saiba como aumentar a sua produtividade com essas dicas', subTitulo:'Manter-se produtivo durante o dia pode ser um grande desafio para algumas pessoas, quando consideramos que a área de desenvolvedores de aplicativos possui um déficit de mão de obra, é...', link: 'desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas' },
    { imagem: 'assets/img/blog/blog9.png', titulo: 'Saiba quais métricas podem te ajudar a ter um app de sucesso', subTitulo:'Confira, neste texto, quais métricas podem te ajudar a ter um app de sucesso. Um aplicativo bem elaborado e que atenda as necessidades dos clientes é um destaque positivo para...', link: 'saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso' },
    { imagem: 'assets/img/blog/blog10.png', titulo: 'Indústria 4.0: Como os aplicativos contribuem para este projeto', subTitulo:'Que nós estamos vivendo uma revolução tecnológica, isso não é novidade. Cada dia é mais comum perceber a presença dos avanços da internet e suas contribuições para a melhoria da...', link: 'industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto' },
    { imagem: 'assets/img/blog/blog11.png', titulo: 'Gamificação: Saiba mais sobre essa tendência que está dominando o mercado', subTitulo:'Você já ouviu falar em gamificação? Provavelmente sim, se não escutou ainda, você deve ter utilizado esse método que vem ganhando popularidade nas empresas do país. O termo vem do...', link: 'gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado' },
    { imagem: 'assets/img/blog/blog12.png', titulo: 'Saiba tudo sobre JavaScript', subTitulo:'As linguagens de programação são as principais ferramentas de um desenvolvedor de sites de aplicativos.  E o JavaScript é uma das linguagens de programação que mais se destaca.  O JavaScript,...', link: 'saiba-tudo-sobre-javascript' },
    { imagem: 'assets/img/blog/blog13.jpg', titulo: 'UX: O Que É E Como Ela Pode Trazer Benefícios Ao Seus Negócios', subTitulo:'Recentemente o termo UX se popularizou e passou a ser muito pesquisado entre as pessoas que têm interesse ou que trabalham com produtos digitais.', link: 'ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios' },
    { imagem: 'assets/img/blog/blog14.jpg', titulo: 'Metologia Ágil: A Forma Simples De Gerenciar Projetos Complexos', subTitulo:'O desenvolvimento de qualquer produto ou solução inovadora, passa por diversas etapas até o lançamento final e validação de resultados. E quando nesse momento de mensuração de resultados a equipe...', link: 'metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos' },
    ]"
    ></app-services>

    <app-contact></app-contact>
</div>