import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  site = 'Develop Valley';

  logo = 'assets/img/logo_develop-valley/logob.png';
  logofooter = 'assets/img/logo_develop-valley/logob.png';
  menu: { link: string, translationKeyOne: string, isRoute: boolean, subItems?: { link: string, text: string }[], isOpen?: boolean, isHovering?: boolean }[] = [
    {
      link: '',
      translationKeyOne: 'Home',
      isRoute: true
    },
    {
      link: 'about-us',
      translationKeyOne: 'navBarTextOne',
      isRoute: true
    },
    {
      link: null,
      translationKeyOne: 'navBarTextTwo',
      isRoute: true,
      subItems: [
        { link: 'https://crm.tolentinos.com', text: 'CRM E GERENCIAMENTO DE PROJETOS' },
        { link: 'https://developvalley.com/social/', text: 'DEVELOP VALLEY (REDE SOCIAL – GOLD)' },
        { link: 'http://diariodoviajantebrasileiro.com.br', text: 'DIÁRIO DO VIAJANTE BRASILEIRO (REDE SOCIAL – LITE)' },
        { link: 'http://integridadetotal.com.br', text: 'INTEGRIDADE TOTAL (COMPLIANCE)' },
        { link: 'http://nobullying.com.br', text: 'NO BULLYING (APP COMBATE AO BULLYING)' },
        { link: 'http://testis.com.br', text: 'TESTIS – APP DE PROVAS E EVENTOS' },
        { link: 'https://leilaoimoveisbrasil.com.br', text: 'LEILAO IMOVEIS BRASIL' }
      ],
      isOpen: false
    },
    {
      link: 'cases',
      translationKeyOne: 'navBarTextThree',
      isRoute: true
    },
    {
      link: 'our-services',
      translationKeyOne: 'navBarTextFour',
      isRoute: true
    },
    {
      link: 'our-blog',
      translationKeyOne: 'Blog',
      isRoute: true
    },
    {
      link: 'our-contact',
      translationKeyOne: 'navBarTextFive',
      isRoute: true
    },
    {
      link: 'vacancy',
      translationKeyOne: 'navBarTextSix',
      isRoute: true
    },
  ]

  constructor(private viewportScroller: ViewportScroller, private translate: TranslateService) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.loadTranslationsName();
  }

  loadTranslationsName() {
    const translationKeyOne = this.menu.map(t => t.translationKeyOne);
  
    this.translate.get(translationKeyOne).subscribe((translations: any) => {
      this.menu.forEach(t => {
        if (translations[t.translationKeyOne]) {
          translations[t.translationKeyOne];
        }
      });
    });
  }

  onLanguageChange() {
    this.loadTranslationsName();
  }
}
