import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios',
  templateUrl: './gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios.component.html',
  styleUrls: ['./gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios.component.scss']
})
export class GestaoEmTiFourFerramentasQueVaoTeAjudarNosSeusNegociosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}