<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let tituloCasosSucesso of titulosCasosSucesso">
            <!-- <h2>{{'teamTextOne' | translate}}<span></span></h2> -->
            <h2>{{tituloCasosSucesso.tituloHTwo}}<span> {{tituloCasosSucesso.subTituloSpan}}</span></h2>
            <p></p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let casoSucesso of casosSucesso" (mouseover)="toggleIcon(casoSucesso, true)" (mouseleave)="toggleIcon(casoSucesso, false)">
                <div class="single-services">
                    <div class="services-img">
                        <img [src]="casoSucesso.imagem" alt="services-img">
                        <a [href]="casoSucesso.link" class="icon">
                            <i class="fa-sharp fa-solid fa-eye" [class.visible]="casoSucesso.showIcon"></i>
                        </a>
                    </div>
                    <div class="services-content">
                        <h3>{{ casoSucesso.titulo }}</h3>
                        <p>{{ casoSucesso.subTitulo }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>