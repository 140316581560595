import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas',
  templateUrl: './o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas.component.html',
  styleUrls: ['./o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas.component.scss']
})
export class OQueFazUmProfissionalFormadoEmDesenvolvimentoDeSistemasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}