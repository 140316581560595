import { Component } from '@angular/core';

@Component({
  selector: 'app-game-vic-engenharia',
  templateUrl: './game-vic-engenharia.component.html',
  styleUrls: ['./game-vic-engenharia.component.scss']
})
export class GameVicEngenhariaComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'game-vic-engenharia',
			alt: 'imagesvisual',
			img: 'assets/img/game-vic-engenharia/vic1.jpeg'
		},
    {
			link: 'game-vic-engenharia',
			alt: 'imagesvisual',
			img: 'assets/img/game-vic-engenharia/vic2.jpeg'
		},
    {
			link: 'game-vic-engenharia',
			alt: 'imagesvisual',
			img: 'assets/img/game-vic-engenharia/vic3.jpeg'
		},
    {
			link: 'game-vic-engenharia',
			alt: 'imagesvisual',
			img: 'assets/img/game-vic-engenharia/vic4.jpeg'
		},
    {
			link: 'game-vic-engenharia',
			alt: 'imagesvisual',
			img: 'assets/img/game-vic-engenharia/vic5.jpeg'
		},
    {
			link: 'game-vic-engenharia',
			alt: 'imagesvisual',
			img: 'assets/img/game-vic-engenharia/vic6.jpeg'
		},
    {
			link: 'game-vic-engenharia',
			alt: 'imagesvisual',
			img: 'assets/img/game-vic-engenharia/vic7.jpeg'
		},
	];

}
