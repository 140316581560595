import { Component } from '@angular/core';

@Component({
  selector: 'app-social-fit',
  templateUrl: './social-fit.component.html',
  styleUrls: ['./social-fit.component.scss']
})
export class SocialFitComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'social-fit',
			alt: 'imagesvisual',
			img: 'assets/img/social-fit/social-fit-1.png'
		},
    {
			link: 'social-fit',
			alt: 'imagesvisual',
			img: 'assets/img/social-fit/social-fit-2.png'
		},
    {
			link: 'social-fit',
			alt: 'imagesvisual',
			img: 'assets/img/social-fit/social-fit-3.png'
		},
    {
			link: 'social-fit',
			alt: 'imagesvisual',
			img: 'assets/img/social-fit/social-fit-4.png'
		},
    {
			link: 'social-fit',
			alt: 'imagesvisual',
			img: 'assets/img/social-fit/social-fit-5.png'
		}
	];
}