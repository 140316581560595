import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso',
  templateUrl: './saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso.component.html',
  styleUrls: ['./saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso.component.scss']
})
export class SaibaQuaisMetricasPodemTeAjudarATerUmAppDeSucessoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}