<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>Arquitetura Da Informação: Entenda O Conceito E Como Aplicá-Lo</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-details.jpg" alt="blog-details"> -->
                        <!-- <div class="date">20 <br> Dec</div> -->
                    </div>
                    <div class="article-text">
                        <!-- <ul class="category">
                            <li><a routerLink="/">IT</a></li>
                            <li><a routerLink="/">Mobile</a></li>
                            <li><a routerLink="/">Marketing</a></li>
                            <li><a routerLink="/">Design</a></li>
                            <li><a routerLink="/">Development</a></li>
                        </ul> -->
                        <!-- <h4 class="title">How to Be a Work From Home Professional</h4> -->
                        <p>Com a chegada da internet, o acesso à informação ficou mais democrático, a partir disso passamos a ter acesso a uma infinidade de conteúdos. Isso ganha ainda mais intensidade quando utilizamos aplicativos ou navegamos através da web.</p>
                        <p>Os usuários, ou a maioria deles, não percebem que durante a execução das suas atividades rotineiras em um computador ou celular, ao acessar sites e aplicativos, principalmente os de maior qualidade, só o fazem devido ao trabalho de profissionais responsáveis por estruturar toda a informação existente nessas plataformas.</p>
                        <p>O principal objetivo disso é fazer com que a experiência do usuário seja melhorada.</p>
                        <p>Esses profissionais costumam ser designers ou analistas de experiência do usuário, ou UX (User Experience), produtores de conteúdo e desenvolvedores.</p>
                        <p>São eles os responsáveis por criar e estruturar os conceitos por trás da arquitetura da informação, desenvolvendo estratégias digitais úteis e intuitivas para os usuários.</p>
                        <br>
                        <h4>Afinal, o que é Arquitetura da Informação?</h4>
                        <br>
                        <P>O conceito surgiu por volta de 1970, juntamente com a semiótica. A arquitetura da Informação é a principal razão para que pudéssemos, facilmente, navegar online através da internet.</P>
                        <P>O seu objetivo é permitir que as informações sejam organizadas, deixando as funcionalidades de uma página web ou aplicativo mais intuitivas e com a navegação simplificada, além de agilizar as buscas online.</P>
                        <P>O responsável pela criação deste conceito foi Richard Saul Wurman, arquiteto e designer gráfico. Ele escreveu mais de 80 livros, sendo um deles chamado Information Anxiety, um marco histórico que delimita o início da era da Arquitetura da Informação.</P>
                        <P>De modo resumido, essa é a área responsável por organizar os resultados que vão ser exibidos toda vez que você realizar uma busca em um site ou aplicativo.</P>
                        <P>Quanto menos tempo você demora para encontrar uma informação, mais será necessário que você visite sites que utilizam e façam bom uso da arquitetura de informação e suas características.</P>
                        <P>Ela é a responsável por organizar todos os elementos de uma página web, softwares e aplicativos, fazendo com que a experiência do usuário seja privilegiada, facilitando sua navegação no conteúdo que está sendo buscado e permitindo que a informação seja entregue de maneira correta.</P>
                        <p>Esse processo pode ser nomeado como design de experiência do usuário ou arquitetura da informação, eles são os grandes responsáveis por identificar os pontos que precisam ser ajustados para melhorar essa experiência.</p>
                        <br>
                        <h4>Qual a importância desse conceito?</h4>
                        <br>
                        <p>Até aqui, nós entendemos que a Arquitetura da Informação é responsável pela organização das informações e isso faz com que a nossa experiência de uso seja aprimorada.</p>
                        <p>Desse modo, ela serve para criar um conteúdo que será entregue para determinado público-alvo, não sendo apenas utilizado em ambientes digitais. Esse conceito pode ser aplicativo para organização de espaços físicos, como bibliotecas, supermercados, arquivos, grandes bancos de dados, até mesmo em campanhas publicitárias.</p>
                        <p>Você perceberá que a Arquitetura da Informação está relacionada a tudo aquilo que possui uma grande quantidade de informações, sendo necessário que elas estejam disponíveis em um só local.</p>
                        <p>Voltando a falar de ambiente digital, a experiência do usuário pode ser trabalhada obedecendo alguns tópicos, como:</p>
                        <br>
                        <h5>Usuários:</h5>
                        <br>
                        <p>persona;</p>
                        <p>necessidades;</p>
                        <p>comportamento de busca pela informação;</p>
                        <p>experiência de uso;</p>
                        <p>tarefas que pretende executar na sua aplicação.</p>
                        <br>
                        <h5>Conteúdo:</h5>
                        <br>
                        <p>textos, imagens, gráficos, conteúdo em áudio etc.;</p>
                        <p>mapeamento das páginas ou telas;</p>
                        <p>estrutura;</p>
                        <p>taxonomia;</p>
                        <p>volume de informações</p>
                        <br>
                        <h5>Contexto:</h5>
                        <br>
                        <p>modelo de negócios;</p>
                        <p>objetivos do projeto;</p>
                        <p>tecnologias e metodologias de desenvolvimento;</p>
                        <p>recursos (capital, pessoas, equipamentos, entre outros);</p>
                        <p>restrições.</p>
                        <br>
                        <h4>Como a Arquitetura da Informação melhora a UX?</h4>
                        <br>
                        <P>Os desenvolvedores, seja de site, aplicativo, ou software, passam a investir em um design totalmente dedicado ao usuário, efetuar o planejamento adequado dessa etapa é essencial para que o projeto obtenha sucesso.</P>
                        <P>É importante não confundir a experiência do usuário, UX, com a Arquitetura da Informação, IA (Information Architecture). Embora eles estejam relacionados, seus conceitos são totalmente diferentes.</P>
                        <P>A Arquitetura da Informação vai fornecer informações essenciais para que o trabalho dos designers responsáveis pela experiência do usuário seja viabilizado.</P>
                        <P>Ela irá oferecer os recursos para estruturação da informação, deixando o sistema compreensível e fácil de ser utilizado, já a UX fica responsável por criar estratégias de interação, fazendo com que a plataforma seja agradável para quem a utiliza.</P>
                        <P>Esses processos de criação vão desde elementos visuais e interface até escolhas que consideram o comportamento do público-alvo e as suas necessidades.</P>
                        <br>
                        <h6>Leia também:</h6>
                        <br>
                        <p>5 Linguagens De Programação Mais Utilizadas Para O Desenvolvimento De Apps<br>
                           MySQL, DB2, Oracle, AWS E GCP: Quais As Maiores Diferenças Entre Esses Bancos De Dados?</p>

                        
                        <!-- <blockquote class="blockquote">
                            <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate.</p>
                        </blockquote> -->
                        <div class="author-share">
                            <div class="row align-items-center">
                                <!-- <div class="col-lg-6 col-md-6">
                                    <div class="article-author">
                                        <div class="author-img">
                                            <img src="assets/img/client-avatar1.jpg" alt="client">
                                        </div>
                                        <div class="author-title">
                                            <h4>John Smith</h4>
                                            <span>Back-End Developer</span>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-12 col-md-6">
                                    <ul class="social-share">
                                        <li><a href="#" tooltip="Share on Facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Instagram"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Vimeo"><i class="fab fa-vimeo-v"></i></a></li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a routerLink="/blog-details"><i class="fas fa-chevron-left"></i> Prev Post</a>
                    </div>
                    <div class="controls-right">
                        <a routerLink="/blog-details">Next Post <i class="fas fa-chevron-right"></i></a>
                    </div>
                </div> -->
                <!-- <div class="post-comments">
                    <h3 class="title mt-0">Comments</h3>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>David Warner</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Eva Amith</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>James Andy</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment mb-0">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Audy Kanna</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="leave-a-reply">
                    <h3 class="title mt-0">Leave a Reply</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" name="name" placeholder="Full Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" class="form-control" name="email" placeholder="E-Mail">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<app-contact></app-contact>