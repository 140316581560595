<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title" style="margin-bottom: 0px;">
            <h2>{{title}} <span>{{titleSpan}}</span></h2>
            <p>{{subTitle.toUpperCase()}}.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title" >
                        <h4>{{preTitle}}</h4>
                        <h2>{{sectionTitle}} <span>{{sectionTitleSpan}}</span></h2>
                        <p>{{sectionTubTitle}}</p>
                    </div>
                    <ul class="features-list" *ngIf="list.length > 0">
                        <li *ngFor="let l of list"><i class="fas fa-check"></i>{{l}}</li>
                    </ul>
                
                    <!-- Lista dentro da tela de Serviços -->
                    <div *ngIf="listTopic || listTopic2 || listTopic3">
                    <ul class="features-listTopic">
                        ​<li *ngIf="listTopic">
                        <i class="fas fa-check"></i>{{listTopic}}
                        <p>{{topic}}</p>​ ​
                        </li>
                        <li *ngIf="listTopic2">
                        <i class="fas fa-check"></i>{{listTopic2}}
                        <p>{{topic2}}</p>​ ​
                        </li>
                        <li *ngIf="listTopic3">
                        <i class="fas fa-check"></i>{{listTopic3}}
                        <p>{{topic3}}</p>​ ​ ​
                        </li>
                    </ul>
                    </div>
                      
                    <p>{{text}}</p>
                    <a *ngIf="button" class="btn btn-primary" (click)="redirectToDetails(button.link)">{{button.text}}</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img [src]="img" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>