import { Component } from '@angular/core';

@Component({
  selector: 'app-integridade-total',
  templateUrl: './integridade-total.component.html',
  styleUrls: ['./integridade-total.component.scss']
})
export class IntegridadeTotalComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'integridade-total',
			alt: 'imagesvisual',
			img: 'assets/img/integridade-total/integridade-total-1.png'
		},
    {
			link: 'integridade-total',
			alt: 'imagesvisual',
			img: 'assets/img/integridade-total/integridade-total-2.png'
		},
    {
			link: 'integridade-total',
			alt: 'imagesvisual',
			img: 'assets/img/integridade-total/integridade-total-3.png'
		},
    {
			link: 'integridade-total',
			alt: 'imagesvisual',
			img: 'assets/img/integridade-total/integridade-total-4.png'
		},
    {
			link: 'integridade-total',
			alt: 'imagesvisual',
			img: 'assets/img/integridade-total/integridade-total-5.png'
		},
    {
			link: 'integridade-total',
			alt: 'imagesvisual',
			img: 'assets/img/integridade-total/integridade-total-6.png'
		},
    {
			link: 'integridade-total',
			alt: 'imagesvisual',
			img: 'assets/img/integridade-total/integridade-total-7.png'
		}
	];
}