import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Vacancy } from 'src/app/models/vacancy.model';
import { VacancyService } from 'src/app/service/vacancy.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vacancy-detail',
  templateUrl: './vacancy-detail.component.html',
  styleUrls: ['./vacancy-detail.component.scss']
})
export class VacancyDetailComponent implements OnInit {

  vacancy: Vacancy;

  constructor(public vacancyService: VacancyService, public route: ActivatedRoute, private sanitizer: DomSanitizer, private translate: TranslateService) { }

  ngOnInit() {
    this.route.params.subscribe(
      param => {
        if (param.id) {
          this.loadVacancy(param.id);
        }
      }
    );
  }

  loadVacancy(id: string): void {
    this.vacancy = this.vacancyService.getById(id);
  }

  itsUrl(url): SafeResourceUrl {
    const sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return sanitized;
  }
}
