import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas',
  templateUrl: './linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas.component.html',
  styleUrls: ['./linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas.component.scss']
})
export class LinguagemDeProgramacaoOQueEEQuaisSaoMaisUtilizadasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}