import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent {
  
  @Input() title: string = '';
  @Input() timeLine = []
  @Input() newTab = 'true';

  constructor(private route: Router) {}

  redirectToDetails(url: string) {

    if(this.newTab == 'true'){
      window.open(url, '_blank');
    }else{
      this.route.navigate([url]);
    }
    
  }
}
