import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto',
  templateUrl: './industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto.component.html',
  styleUrls: ['./industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto.component.scss']
})
export class IndustriaFourZeroComoOsAplicativosContribuemParaEsteProjetoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}