import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas',
  templateUrl: './desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas.component.html',
  styleUrls: ['./desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas.component.scss']
})
export class DesenvolvedorSaibaComoAumentarASuaProdutividadeComEssasDicasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}