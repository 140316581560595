<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4 text-center text-md-left p-30">
                <a class="brand" href="/">
                    <img class="mw-100 mx-auto mx-md-0" [src]="logo" style="width: 330px !important;">
                </a>
                <p>
                    {{ 'footerTextOne' | translate }}
                </p>
            </div>
            <div class="col-md-4 text-left p-30 blw">
                <h5 class="text-primary font-weight-bold">{{ 'footerTextTwo' | translate }}</h5>

                <ul class="nav nav-list">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom">
                        {{ 'footerTextThree' | translate }}</li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom ">
                        {{ 'footerTextFour' | translate }}</li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom ">
                        {{ 'footerTextFive' | translate }}</li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom ">
                        {{ 'footerTextSix' | translate }}</li>
                </ul>

            </div>
            <div class="col-md-4 text-left text-md-right p-30 blw">
                <h5 class="text-primary font-weight-bold">{{ 'footerTextSeven' | translate }}</h5>
                <ul class="nav nav-list">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom">
                        <i _ngcontent-ng-c999160338="" class="fas fa-phone-alt"></i>
                        &nbsp;(31) 4042-2995 (WhatsApp)
                    </li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom">
                        <i _ngcontent-ng-c999160338="" class="fas fa-phone-alt"></i>
                        &nbsp;(41) 4042-2994
                    </li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom" style="margin-top: 30px;">
                        <i _ngcontent-ng-c999160338="" class="far fa-envelope"></i>
                        &nbsp;contato@developvalley.com
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">

    <a href="https://api.whatsapp.com/send?phone=553140422995&text=Ol%C3%A1%2C+estive+no+site+da+Develop+Valley+e+gostaria+de+conversar+com+voc%C3%AAs." class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
        </a>

    <div class="container" style="margin-top: 30px;">
        <!-- <h3><a routerLink="/"><span>T</span>olentinos</a></h3> -->
        <ul>
            <li><a href="https://www.facebook.com/somosdevelopvalley/" target="_blank" class="fab fa-facebook-f"></a></li>
            <!-- <li><a href="#" target="_blank" class="fab fa-twitter"></a></li> -->
            <li><a href="https://www.linkedin.com/company/develop-valley-corp" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="https://www.instagram.com/somosdevelopvalley/" target="_blank" class="fab fa-instagram"></a></li>
            <!-- <li><a href="#" target="_blank" class="fab fa-skype"></a></li> -->
        </ul>
        <p>Copyright {{year}} © {{site}}
        </p>
    </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>