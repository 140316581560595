<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <!-- <span>X</span>avro -->
            <img [src]="logo" [ngStyle]="{'width.px': isResponsiveMode ? 100 : 200}">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <ng-container *ngFor="let menuItem of menu">
                    <li class="nav-item" (mouseenter)="handleMouseEnter(menuItem)" (mouseleave)="handleMouseLeave(menuItem)">
                        <a *ngIf="menuItem.isRoute; else externalLink" [routerLink]="menuItem.link" class="nav-link">{{ menuItem.translationKeyOne | translate }}</a>
                        <ng-template #externalLink>
                            <a [href]="menuItem.link" target="_blank" class="nav-link">{{ menuItem.text }}</a>
                        </ng-template>
                        <ul *ngIf="menuItem.subItems && menuItem.isOpen" class="position-absolute" [ngClass]="{'sub-menu-background': menuItem.isOpen}"
                        (mouseenter)="handleMouseEnter(menuItem)" (mouseleave)="handleMouseLeave(menuItem)">
                            <li *ngFor="let subItem of menuItem.subItems">
                              <a *ngIf="subItem.isRoute; else subExternalLink" [routerLink]="subItem.link" class="nav-sub-menu">{{ subItem.text }}</a>
                              <ng-template #subExternalLink>
                                <a href="javascript:void(0);" (click)="handleSubItemClick($event, subItem)" class="nav-sub-menu">{{ subItem.text }}</a>
                              </ng-template>
                            </li>
                          </ul>
                    </li>
                </ng-container>​​​ ​ ​ ​ ​ ​ ​
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <ng-container *ngFor="let menuItem of menu">
                    <li class="nav-item" (mouseenter)="handleMouseEnter(menuItem)" (mouseleave)="handleMouseLeave(menuItem)">
                        <a *ngIf="menuItem.isRoute; else externalLink" [routerLink]="menuItem.link" class="nav-link" (click)="closeNavbar(menuItem)">{{ menuItem.translationKeyOne | translate }}</a>
                        <ng-template #externalLink>
                            <a [href]="menuItem.link" target="_blank" class="nav-link" (click)="closeNavbar(menuItem)">{{ menuItem.text }}</a>
                        </ng-template>
                        <ul *ngIf="menuItem.subItems && menuItem.isOpen" class="position-absolute-mobile" [ngClass]="{'sub-menu-background': menuItem.isOpen}"
                        (mouseenter)="handleMouseEnter(menuItem)" (mouseleave)="handleMouseLeave(menuItem)">
                            <li *ngFor="let subItem of menuItem.subItems">
                              <a *ngIf="subItem.isRoute; else subExternalLink" [routerLink]="subItem.link" class="nav-sub-menu">{{ subItem.text }}</a>
                              <ng-template #subExternalLink>
                                <a href="javascript:void(0);" (click)="handleSubItemClick($event, subItem)" class="nav-sub-menu">{{ subItem.text }}</a>
                              </ng-template>
                            </li>
                          </ul>
                    </li>
                </ng-container>
            </ul>
        </div>

        <select
            [ngClass]="{'custom-select': !isResponsiveMode, 'custom-select-mobile': isResponsiveMode}"
            #selectedLang
            (change)="switchLang(selectedLang.value)">
            <option disabled selected value="">{{ 'languageTextOne' | translate }}</option>
                <option *ngFor="let language of translate.getLangs()" 
                        [value]="language"
                        [selected]="language === translate.currentLang">
                            {{ language }}
                </option>
        </select>

    </div>
</nav>
