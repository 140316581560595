<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul class="contact-list">
                                <li><i class="fas fa-map-marker-alt"></i> <a><span>{{'ourContactTextTwo' | translate}}</span>Belo Horizonte - Minas Gerais <br> Foz do Iguaçu - Paraná</a></li>
                                <li><i class="far fa-envelope"></i> <a><span>Email:</span>contato@tolentinos.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a><span>{{'ourContactTextThree' | translate}}</span>(31)4042-2995 OU (41)4042-2994</a></li>
                                <!-- <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
