import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos',
  templateUrl: './metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos.component.html',
  styleUrls: ['./metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos.component.scss']
})
export class MetologiaAgilAFormaSimplesDeGerenciarProjetosComplexosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
