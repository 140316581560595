<div class="page-title-area item-bg-12">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>Saiba Tudo Sobre JavaScript</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-details.jpg" alt="blog-details"> -->
                        <!-- <div class="date">20 <br> Dec</div> -->
                    </div>
                    <div class="article-text">
                        <!-- <ul class="category">
                            <li><a routerLink="/">IT</a></li>
                            <li><a routerLink="/">Mobile</a></li>
                            <li><a routerLink="/">Marketing</a></li>
                            <li><a routerLink="/">Design</a></li>
                            <li><a routerLink="/">Development</a></li>
                        </ul> -->
                        <!-- <h4 class="title">How to Be a Work From Home Professional</h4> -->
                        <p>As linguagens de programação são as principais ferramentas de um desenvolvedor de sites de aplicativos.  E o JavaScript é uma das linguagens de programação que mais se destaca.</p>
                        <br>
                        <h4>O JavaScript, popular e de fácil utilização</h4>
                        <br>
                        <p>Uma das linguagens de programação mais populares e utilizadas na área de TI atualmente é o JavaScript.</p>
                        <p>Criado por Brendan Eich, da Netscape, um dos principais responsáveis pelos navegadores de web. De início, essa linguagem possuia o nome de LiveScript e com pouco tempo depois passou a ser chamado de JavaScript e rapidamente ganhou popularidade por ser uma linguagem fácil de ser aprendida.</p>
                        <p>O JavaScript é uma linguagem fácil de ser interpretada e baseada em objetos com funções de primeira classe, mais conhecidas como linguagem de script para páginas na web.</p>
                        <p>Ela é uma linguagem que se baseia em protótipos, multi-paradigmas e dinâmicas, por isso, suporta estilos de orientação a objetos, imperativos e declarativos.</p>
                        <p>Antigamente, por volta dos anos 90, os usuários possuíam uma experiência muito diferente da que estamos acostumados nos dias de hoje.</p>
                        <p>Em versões anteriores dos navegadores, as páginas dos sites eram estáticas e criadas para responder ações de uma forma muito limitada, demorando para que as ações pudessem ser visualizadas.</p>
                        <p>Isso era comum de acontecer devido às ordens que haviam sido planejadas nesses navegadores, elas precisavam ser enviadas a um servidor externo e somente lá que eram executadas, após isso ocorria o retorno ao ponto que enviou o comando.</p>
                        <p>O JavaScript foi criado com o objetivo de deixar a navegação de internet mais rápida e fácil, com os scripts dessa linguagem foi possível incluir elementos dinâmicos como mapas, formulários, animações, infográficos que permitem interação, ou seja, as páginas estáticas ficaram no passado.</p>
                        <p>Atualmente essa linguagem é uma das tecnologias mais importantes de front-end, junto com HTML, CSS e PHP. Este conjunto de linguagens são responsáveis por abraçar as necessidades existentes para o desenvolver uma página completa, dinâmica e que performe de um modo impressionante, para se ter noção, grandes gigantes do mercado utilizam o JavaScript como linguagem, a exemplo do <strong>Facebook, Ebay, LinkedIn e o Yahoo.</strong></p>
                        <br>
                        <h4>JavaScript é diferente de Java, ok?</h4>
                        <br>
                        <p>Uma dúvida comum de quem está começando agora no mercado e a lidar com linguagens de programação, o JavaScript e o Java não são a mesma coisa.</p>
                        <p>O Java foi criado em 1995 por James Hosling e naquela época, e até os dias de hoje, era bastante utilizado e popular.</p>
                        <p>Como dissemos, o JavaScript antes tinha o nome de LiveScpipt e como a demanda cresceu, ficou decidido unir ambas, dessa forma a nova linguagem ganhou credibilidade com o nome de outra já consolidada na área.</p>
                        <br>
                        <h4>De que maneira essa linguagem funciona</h4>
                        <br>
                        <p>Criada originalmente num formato client-side, linguagens executadas do lado de quem as utiliza, nessa situação são executadas pelo navegador de quem está utilizando. Isso quer dizer que as ações realizadas durante o uso são feitas no computador, notebook e dispositivo móvel de quem está fazendo o uso, dando as respostas ao usuário de maneira imediata.</p>
                        <p>Para entender melhor vamos te dar um exemplo:</p>
                        <p>Imagine que você está realizando a compra de passagens áreas para uma viagem que planejou, no site da companhia área que você escolheu, você seleciona o local de origem e destino e data, então a página retornará com informações como horário e valores.</p>
                        <p>Ao selecionar as passagens, você percebe que no campo direito da tela constam os valores e o campo para inserir um cupom de desconto, caso você faça isso, perceberá que o valor será alterado.</p>
                        <p>Para esse caso, podemos dizer que essa alteração é executada com comandos programados no JavaScript e ele pode ser utilizado em diversas aplicações, como desenvolvimento na web e mobile, games, programas e sistemas back-end.</p>
                        <br>
                        <h4>Os benefícios</h4>
                        <br>
                        <p>O JavaScript se destaca por possuir uma série de vantagens, entre elas, as principais são:</p>
                        <br>
                        <ol>
                            <li>Versatilidade da linguagem;</li><br>
                            <li>Rapidez de leitura e, portanto, rapidez de execução;</li><br>
                            <li>Sintaxe acessível;</li><br>
                            <li>Ótima linguagem para iniciantes em programação;</li><br>
                            <li>Compatível com uma grande variedade de navegadores e plataformas.</li><br>
                        </ol>
                        <br>
                        <p>Vamos deixar um guia aqui caso você tenha interesse em aprender um pouco sobre<br> essa linguagem, principalmente se quiser começar a entendê-la, é só clicar <a href="https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Guide" target="_blank">aqui</a>.</p>


                        <!-- <blockquote class="blockquote">
                            <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate.</p>
                        </blockquote> -->
                        <div class="author-share">
                            <div class="row align-items-center">
                                <!-- <div class="col-lg-6 col-md-6">
                                    <div class="article-author">
                                        <div class="author-img">
                                            <img src="assets/img/client-avatar1.jpg" alt="client">
                                        </div>
                                        <div class="author-title">
                                            <h4>John Smith</h4>
                                            <span>Back-End Developer</span>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-12 col-md-6">
                                    <ul class="social-share">
                                        <li><a href="#" tooltip="Share on Facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Instagram"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#" target="_blank" tooltip="Share on Vimeo"><i class="fab fa-vimeo-v"></i></a></li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a routerLink="/blog-details"><i class="fas fa-chevron-left"></i> Prev Post</a>
                    </div>
                    <div class="controls-right">
                        <a routerLink="/blog-details">Next Post <i class="fas fa-chevron-right"></i></a>
                    </div>
                </div> -->
                <!-- <div class="post-comments">
                    <h3 class="title mt-0">Comments</h3>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>David Warner</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Eva Amith</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>James Andy</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                    <div class="single-comment mb-0">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Audy Kanna</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a routerLink="/blog-details">Reply</a>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="leave-a-reply">
                    <h3 class="title mt-0">Leave a Reply</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" name="name" placeholder="Full Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" class="form-control" name="email" placeholder="E-Mail">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<app-contact></app-contact>