import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

  timeLine = [
    { year: '2008', translationKeyOne: 'timelineTextOne', translationKeyTwo: 'timelineTextTwo' },
    { year: '2011', translationKeyOne: 'timelineTextThree', translationKeyTwo: 'timelineTextFour'},
    { year: '2013', translationKeyOne: 'WEBHOSTING', translationKeyTwo: 'timelineTextFive'},
    { year: '2017', translationKeyOne: 'TOLENTINOS HOLDING', translationKeyTwo: 'timelineTextSix' },
    { year: '2018', translationKeyOne: 'WEVINC (VINC TECNOLOGIA)', translationKeyTwo: 'timelineTextSeven'},
    { year: '2018', translationKeyOne: 'timelineTextEight', translationKeyTwo: 'timelineTextNine', image: 'assets/img/time-line/certificado.jpeg'},
    { year: '2019', translationKeyOne: 'timelineTextTen', translationKeyTwo: 'timelineTextEleven'},
    { year: '2020', translationKeyOne: 'timelineTextTwelve', translationKeyTwo: 'timelineTextThirteen'},
    { year: '2021', translationKeyOne: 'timelineTextFourteen', translationKeyTwo: 'timelineTextFifteen', image: 'assets/img/time-line/pti.jpeg', translationKeyThree: 'timelineButtonOne', url: 'https://www.pti.org.br/pti-tem-nova-empresa-residente-especializada-no-desenvolvimento-de-solucoes-tecnologicas/'},
    { year: '2023', translationKeyOne: 'timelineTextSixteen', translationKeyTwo: 'timelineTextSeventeen', image: 'assets/img/logo_develop-valley/logopp.png'}
    ]

  constructor(private viewportScroller: ViewportScroller, private translate: TranslateService) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.loadTranslationsName();
  }

  loadTranslationsName() {
    const translationKeyOne = this.timeLine.map(t => t.translationKeyOne);
    const translationKeyTwo = this.timeLine.map(t => t.translationKeyTwo);
    const translationKeyThree = this.timeLine.map(t => t.translationKeyThree);
  
    this.translate.get(translationKeyOne, translationKeyTwo && translationKeyThree).subscribe((translations: any) => {
      this.timeLine.forEach(t => {
        if (translations[t.translationKeyOne, t.translationKeyTwo, t.translationKeyThree]) {
          translations[t.translationKeyOne, t.translationKeyTwo, t.translationKeyThree];
        }
      });
    });
  }

  onLanguageChange() {
    this.loadTranslationsName();
  }

}
