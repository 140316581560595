<div>
    <app-featured
    [background]="'assets/img/featured/preto.jpg'"
    [preTitle]="''"
    [title]="'totvsTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
    </app-featured>

    <app-text-right-img-left 
    [preTitle]="''"
    [title]="'PO-UI-'" [titleSpan]="'TOTVS'"
    [subTitle]="''"
    [img]="'assets/img/po-ui-totvs/totvs2.jpg'"
    [sectionTitle]="''"
    [sectionTitleSpan]="''"
    [listTopic]="'PO-UI'"
    [topic]="'totvsTextTwo' | translate"
    [listTopic2]="'totvsTextThree' | translate"
    [topic2]="'totvsTextFour' | translate"
    [listTopic3]="'totvsTextFive' | translate"
    [topic3]="'totvsTextSix' | translate"
    [listTopic4]="'totvsTextSeven' | translate"
    [topic4]="'totvsTextEight' | translate"
    [button]="">
    </app-text-right-img-left>

    <app-partner [list]="listPartners" [background]="'assets/img/bg2.jpg'">
    </app-partner>

    <app-contact></app-contact>
</div>