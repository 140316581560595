import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CasesComponent } from './pages/cases/cases.component';
import { OurContactComponent } from './pages/our-contact/our-contact.component';
import { VacancyComponent } from './pages/vacancy/vacancy.component';
import { PoUiTotvsComponent } from './pages/cases/po-ui-totvs/po-ui-totvs.component';
import { GameVicEngenhariaComponent } from './pages/cases/game-vic-engenharia/game-vic-engenharia.component';
import { ClubePrimeBoxComponent } from './pages/cases/clube-prime-box/clube-prime-box.component';
import { OurBlogComponent } from './pages/our-blog/our-blog.component';
import { EntendaOQueSaoPlataformasLowCodeEAsSuasVantagensComponent } from './pages/our-blog/entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens/entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens.component';
import { ArquiteturaDaInformacaoEntendaOConceitoEComoAplicaLoComponent } from './pages/our-blog/arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo/arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo.component';
import { LinguagemDeProgramacaoOQueEEQuaisSaoMaisUtilizadasComponent } from './pages/our-blog/linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas/linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas.component';
import { OQueFazUmProfissionalFormadoEmDesenvolvimentoDeSistemasComponent } from './pages/our-blog/o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas/o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas.component';
import { GestaoEmTiFourFerramentasQueVaoTeAjudarNosSeusNegociosComponent } from './pages/our-blog/gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios/gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios.component';
import { TecnologiaNoBrasilConhecaOsPrincipaisEventosDoSegmentoNoPaisComponent } from './pages/our-blog/tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais/tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais.component';
import { DesenvolvedorSaibaComoAumentarASuaProdutividadeComEssasDicasComponent } from './pages/our-blog/desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas/desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas.component';
import { SaibaQuaisMetricasPodemTeAjudarATerUmAppDeSucessoComponent } from './pages/our-blog/saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso/saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso.component';
import { IndustriaFourZeroComoOsAplicativosContribuemParaEsteProjetoComponent } from './pages/our-blog/industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto/industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto.component';
import { GamificacaoSaibaMaisSobreEssaTendenciaQueEstaDominandoOMercadoComponent } from './pages/our-blog/gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado/gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado.component';
import { SaibaTudoSobreJavascriptComponent } from './pages/our-blog/saiba-tudo-sobre-javascript/saiba-tudo-sobre-javascript.component';
import { VacancyDetailComponent } from './pages/vacancy/vacancy-detail/vacancy-detail.component';
import { HomeOfficeComoOModeloPodeSerVantajosoParaParaOsDesenvolvedoresDeAppComponent } from './pages/our-blog/home-office-como-o-modelo-pode-ser-vantajoso-para-para-os-desenvolvedores-de-app/home-office-como-o-modelo.component';
import { UxOQueEEComoElaPodeTrazerBeneficiosAoSeusNegociosComponent } from './pages/our-blog/ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios/ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios.component';
import { MetologiaAgilAFormaSimplesDeGerenciarProjetosComplexosComponent } from './pages/our-blog/metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos/metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos.component';
import { NoBullyingComponent } from './pages/cases/no-bullying/no-bullying.component';
import { SocialFitComponent } from './pages/cases/social-fit/social-fit.component';
import { VicComplianceComponent } from './pages/cases/vic-compliance/vic-compliance.component';
import { SociengeComplianceComponent } from './pages/cases/socienge-compliance/socienge-compliance.component';
import { IntegridadeTotalComponent } from './pages/cases/integridade-total/integridade-total.component';
import { TestisComponent } from './pages/cases/testis/testis.component';
import { BeerTimeClubComponent } from './pages/cases/beer-time-club/beer-time-club.component';
import { LeilaoImoveisBrasilComponent } from './pages/cases/leilao-imoveis-brasil/leilao-imoveis-brasil.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'our-services', component: OurServicesComponent},
  {path: 'our-blog', component: OurBlogComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'cases', component: CasesComponent},
  {path: 'our-contact', component: OurContactComponent},
  {path: 'vacancy', component: VacancyComponent},
  {path: 'po-ui-totvs', component: PoUiTotvsComponent},
  {path: 'game-vic-engenharia', component: GameVicEngenhariaComponent},
  {path: 'clube-prime-box', component: ClubePrimeBoxComponent},
  {path: 'entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens', component: EntendaOQueSaoPlataformasLowCodeEAsSuasVantagensComponent},
  {path: 'arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo', component: ArquiteturaDaInformacaoEntendaOConceitoEComoAplicaLoComponent},
  {path: 'linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas', component: LinguagemDeProgramacaoOQueEEQuaisSaoMaisUtilizadasComponent},
  {path: 'o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas', component: OQueFazUmProfissionalFormadoEmDesenvolvimentoDeSistemasComponent},
  {path: 'gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios', component: GestaoEmTiFourFerramentasQueVaoTeAjudarNosSeusNegociosComponent},
  {path: 'tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais', component: TecnologiaNoBrasilConhecaOsPrincipaisEventosDoSegmentoNoPaisComponent},
  {path: 'home-office-como-o-modelo-pode-ser-vantajoso-para-para-os-desenvolvedores-de-app', component: HomeOfficeComoOModeloPodeSerVantajosoParaParaOsDesenvolvedoresDeAppComponent},
  {path: 'desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas', component: DesenvolvedorSaibaComoAumentarASuaProdutividadeComEssasDicasComponent},
  {path: 'saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso', component: SaibaQuaisMetricasPodemTeAjudarATerUmAppDeSucessoComponent},
  {path: 'industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto', component: IndustriaFourZeroComoOsAplicativosContribuemParaEsteProjetoComponent},
  {path: 'gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado', component: GamificacaoSaibaMaisSobreEssaTendenciaQueEstaDominandoOMercadoComponent},
  {path: 'saiba-tudo-sobre-javascript', component: SaibaTudoSobreJavascriptComponent},
  {path: 'ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios', component: UxOQueEEComoElaPodeTrazerBeneficiosAoSeusNegociosComponent},
  {path: 'metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos', component: MetologiaAgilAFormaSimplesDeGerenciarProjetosComplexosComponent},
  {path: 'vacancy-detail/:id', component: VacancyDetailComponent},
  {path: 'no-bullying', component: NoBullyingComponent},
  {path: 'social-fit', component: SocialFitComponent},
  {path: 'vic-compliance', component: VicComplianceComponent},
  {path: 'socienge-compliance', component: SociengeComplianceComponent},
  {path: 'integridade-total', component: IntegridadeTotalComponent},
  {path: 'testis', component: TestisComponent},
  {path: 'beer-time-club', component: BeerTimeClubComponent},
  {path: 'leilao-imoveis-brasil', component: LeilaoImoveisBrasilComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
