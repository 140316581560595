import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';

@Component({
	selector: 'app-partner',
	templateUrl: './partner.component.html',
	styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

	@Input() list: { link: string, img: string, alt: string }[] = [];
	@Input() background = 'assets/img/bg1.jpg';

	constructor(private viewportScroller: ViewportScroller) { }

	public onClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	ngOnInit() {
	}

	partnerSlides: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='fa fa-angle-left'></i>",
			"<i class='fa fa-angle-right'></i>"
		],
		responsive: {
			0: {
				items: 2,
			},
			576: {
				items: 3,
			},
			768: {
				items: 4,
			},
			1200: {
				items: 6,
			}
		}
	}

}