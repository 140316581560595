import { Injectable } from '@angular/core';
import { Vacancy } from '../models/vacancy.model';

@Injectable({
  providedIn: 'root'
})
export class VacancyService {

  constructor() { }

  vacans: Vacancy[]=[
    {
      id: 'vaga-desenvolvedor-angular-pleno-develop-valley',
      year: '', 
      translationKeyOne: 'vacancyTextTwo', 
      detail: '', 
      image: 'assets/img/time-line/angular.png', 
      translationKeyThree: 'vacancyTextThree', 
      url: `vacancy-detail/vaga-desenvolvedor-angular-pleno-develop-valley`,
      itsUrl: 'https://its.tolentinos.com/forms/wtl/03d310b4dbe48a247249f466e8d8dafe',
      title: "vacancyTextFive",
      detailsOne: "vacancyTextEight",
      detailsTwo: "vacancyTextNine",
      detailsThree: "vacancyTextTen",
      detailsFour: "vacancyTextEleven",
      detailsFive: "vacancyTextTwelve",
      detailsSix: "vacancyTextThirteen",
      subTitle: "vacancyTextFourteen",
      vacancy: "vacancyTextFifteen",
      topic: "vacancyTextSixteen",
      subTopicOne: "vacancyTextSeventeen",
      subTopicTwo: "vacancyTextEighteen",
      subTopicThree: "vacancyTextNineteen",
      topicTwo: "vacancyTextTwenty",
      subTopicFour: "vacancyTextTwentyOne",
      subTopicFive: "vacancyTextTwentyTwo",
      subTopicSix: "vacancyTextTwentyThree"
    },
    {
      id: 'vaga-desenvolvedor-nestjs-senior-develop-valley',
      year: '', 
      translationKeyOne: 'vacancyTextFour', 
      detail: '', 
      image: 'assets/img/time-line/nest.png', 
      translationKeyThree: 'vacancyTextThree', 
      url: `vacancy-detail/vaga-desenvolvedor-nestjs-senior-develop-valley`,
      itsUrl: 'https://its.tolentinos.com/forms/wtl/851b594e9f4b29446cd2e151d3da2ae1',
      title: "vacancyTextTwentyFive",
      detailsOne: "vacancyTextEight",
      detailsTwo: "vacancyTextNine",
      detailsThree: "vacancyTextTen",
      detailsFour: "vacancyTextTwentySix",
      detailsFive: "vacancyTextTwelve",
      detailsSix: "vacancyTextThirteen",
      subTitle: "vacancyTextTwentySeven",
      vacancy: "vacancyTextTwentyEight",
      topic: "vacancyTextTwentyNine",
      subTopicOne: "vacancyTextThirty",
      subTopicTwo: "vacancyTextThirtyOne",
      subTopicThree: "vacancyTextThirtyTwo",
      topicTwo: "vacancyTextThirtyThree",
      subTopicFour: "vacancyTextThirtyFour",
      subTopicFive: "vacancyTextThirtyFive",
      subTopicSix: "vacancyTextThirtySix"
    },
  ]

  vacancysList(): Vacancy[]{
    return this.vacans;
  }

  getById(id: string): Vacancy{
    return this.vacans.find(elem=>elem.id == id);
  }

}
