<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img [src]="img" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>{{preTitle}}</h4>
                        <h2>{{title}} <span>{{titleSpan}}</span></h2>
                        <p>{{sectionTubTitle}}</p>
                    </div>
                    <ul class="features-list" *ngIf="list.length > 0">
                        <li *ngFor="let l of list"><i class="fas fa-check"></i>{{l}}</li>
                    </ul>

                    <!-- Lista dentro da tela de Serviços -->
                    <div *ngIf="listTopic || listTopic2 || listTopic3 || listTopic4">
                        <ul class="features-listTopic">
                            ​<li *ngIf="listTopic">
                            <i class="fas fa-check"></i>{{listTopic}}
                            <p>{{topic}}</p>​ ​
                            </li>
                            <li *ngIf="listTopic2">
                            <i class="fas fa-check"></i>{{listTopic2}}
                            <p>{{topic2}}</p>​ ​
                            </li>
                            <li *ngIf="listTopic3">
                            <i class="fas fa-check"></i>{{listTopic3}}
                            <p>{{topic3}}</p>​ ​ ​
                            </li>
                            <li *ngIf="listTopic4">
                            <i class="fas fa-check"></i>{{listTopic4}}
                            <p>{{topic4}}</p>​ ​ ​
                            </li>
                        </ul>
                        </div>

                    <p>{{text}}</p>
                    <a *ngIf="button" class="btn btn-primary" (click)="redirectToDetails(button.link)">{{button.text}}</a>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>