import { Component } from '@angular/core';

@Component({
  selector: 'app-clube-prime-box',
  templateUrl: './clube-prime-box.component.html',
  styleUrls: ['./clube-prime-box.component.scss']
})
export class ClubePrimeBoxComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'clube-prime-box',
			alt: 'imagesvisual',
			img: 'assets/img/clube-prime-box/club1.png'
		},
    {
			link: 'clube-prime-box',
			alt: 'imagesvisual',
			img: 'assets/img/clube-prime-box/club2.png'
		},
    {
			link: 'clube-prime-box',
			alt: 'imagesvisual',
			img: 'assets/img/clube-prime-box/club3.png'
		},
    {
			link: 'clube-prime-box',
			alt: 'imagesvisual',
			img: 'assets/img/clube-prime-box/club4.png'
		},
    {
			link: 'clube-prime-box',
			alt: 'imagesvisual',
			img: 'assets/img/clube-prime-box/club5.png'
		},
    {
			link: 'clube-prime-box',
			alt: 'imagesvisual',
			img: 'assets/img/clube-prime-box/club6.png'
		}
	];
}
