import { Component } from '@angular/core';

@Component({
  selector: 'app-our-contact',
  templateUrl: './our-contact.component.html',
  styleUrls: ['./our-contact.component.scss']
})
export class OurContactComponent {

}
