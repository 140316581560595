import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-two',
  templateUrl: './featured-two.component.html',
  styleUrls: ['./featured-two.component.scss']
})
export class FeaturedTwoComponent implements OnInit {

  @Input() preTitle;
  @Input() title;
  @Input() subTitle;
  @Input() buttonOne: { text, link } = undefined;
  @Input() buttonTwo: { text, link } = undefined;
  @Input() background = 'assets/img/featured/ctr-bg.jpg';
  
  constructor(private viewportScroller: ViewportScroller) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

  goToRoute(link) {

  }

  redirectToDetails(url: string) {
    window.open(url, '_self');
  }
}