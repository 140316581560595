<div>
    <app-featured
    [background]="'assets/img/featured/service.jpeg'"
    [preTitle]="''"
    [title]="'ourServiceTextOne' | translate"
    [subTitle]="''"
    [buttonOne]=""
    >
</app-featured>

<app-text-left-img-right 
    [title]="'ourServiceTextTwo' | translate" 
    [titleSpan]="'ourServiceTextThree' | translate"
    [subTitle]="''"
    [img]="'assets/img/our-services/cell-toll.png'"
    [sectionTitle]="'ourServiceTextFour' | translate"
    [sectionTitleSpan]="'ourServiceTextFive' | translate"
    [listTopic]="'ourServiceTextSix' | translate"
    [topic]="'ourServiceTextSeven' | translate"
    [listTopic2]="'ourServiceTextEight' | translate"
    [topic2]="'ourServiceTextNine' | translate"
    [listTopic3]="'ourServiceTextTen' | translate"
    [topic3]="'ourServiceTextEleven' | translate"
    [button]="{text:'ourServiceTextTwelve' | translate,link:'/our-contact'}">
</app-text-left-img-right>

<app-text-right-img-left 
    [preTitle]="''"
    [title]="'ourServiceTextThirteen' | translate " [titleSpan]="'ourServiceTextFourteen' | translate"
    [subTitle]="''"
    [img]="'assets/img/our-services/laptop-toll.png'"
    [sectionTitle]=""
    [sectionTitleSpan]=""
    [listTopic]="'ourServiceTextFifteen' | translate"
    [topic]="'ourServiceTextSixteen' | translate"
    [listTopic2]="'ourServiceTextSeventeen' | translate"
    [topic2]="'ourServiceTextEighteen' | translate"
    [listTopic3]="'ourServiceTextNineteen' | translate"
    [topic3]="'ourServiceTextTwenty' | translate"
    [button]="{text:'ourServiceTextTwentyOne' | translate,link:'/our-contact'}">
</app-text-right-img-left>

<app-text-left-img-right 
    [title]="'ourServiceTextTwentyTwo' | translate" 
    [titleSpan]="'ourServiceTextTwentyThree' | translate"
    [subTitle]="''"
    [img]="'assets/img/our-services/desktop-toll.png'"
    [sectionTitle]="''"
    [sectionTitleSpan]="''"
    [listTopic]="'ourServiceTextTwentyFour' | translate"
    [topic]="'ourServiceTextTwentyFive' | translate"
    [listTopic2]="'ourServiceTextTwentySix' | translate"
    [topic2]="'ourServiceTextTwentySeven' | translate"
    [listTopic3]="'ourServiceTextTwentyEight' | translate"
    [topic3]="'ourServiceTextTwentyNine' | translate"
    [button]="{text:'ourServiceTextThirty' | translate,link:'/our-contact'}">
</app-text-left-img-right>

<app-contact></app-contact>
</div>
