import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent {

  constructor(private viewportScroller: ViewportScroller) { }

	public onClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}
  
}
