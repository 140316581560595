import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import { FlipComponent } from './components/flip/flip.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TextLeftImgRightComponent } from './components/text-left-img-right/text-left-img-right.component';
import { TextRightImgLeftComponent } from './components/text-right-img-left/text-right-img-left.component';
import { FeaturedComponent } from './components/featured/featured.component';
import { PartnerComponent } from './components/partner/partner.component';
import { ContactComponent } from './components/contact/contact.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FooterComponent } from './components/footer/footer.component';
import { NgParticlesModule } from 'ng-particles';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CasesComponent } from './pages/cases/cases.component';
import { TeamComponent } from './components/team/team.component';
import { TimeLineComponent } from './components/time-line/time-line.component';
import { OurContactComponent } from './pages/our-contact/our-contact.component';
import { VacancyComponent } from './pages/vacancy/vacancy.component';
import { PoUiTotvsComponent } from './pages/cases/po-ui-totvs/po-ui-totvs.component';
import { GameVicEngenhariaComponent } from './pages/cases/game-vic-engenharia/game-vic-engenharia.component';
import { ClubePrimeBoxComponent } from './pages/cases/clube-prime-box/clube-prime-box.component';
import { OurBlogComponent } from './pages/our-blog/our-blog.component';
import { SummaryContactsComponent } from './components/summary-contacts/summary-contacts.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { BlogComponent } from './components/blog/blog.component';
import { EntendaOQueSaoPlataformasLowCodeEAsSuasVantagensComponent } from './pages/our-blog/entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens/entenda-o-que-sao-plataformas-low-code-e-as-suas-vantagens.component';
import { ArquiteturaDaInformacaoEntendaOConceitoEComoAplicaLoComponent } from './pages/our-blog/arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo/arquitetura-da-informacao-entenda-o-conceito-e-como-aplica-lo.component';
import { LinguagemDeProgramacaoOQueEEQuaisSaoMaisUtilizadasComponent } from './pages/our-blog/linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas/linguagem-de-programacao-o-que-e-e-quais-sao-mais-utilizadas.component';
import { OQueFazUmProfissionalFormadoEmDesenvolvimentoDeSistemasComponent } from './pages/our-blog/o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas/o-que-faz-um-profissional-formado-em-desenvolvimento-de-sistemas.component';
import { GestaoEmTiFourFerramentasQueVaoTeAjudarNosSeusNegociosComponent } from './pages/our-blog/gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios/gestao-em-ti-four-ferramentas-que-vao-te-ajudar-nos-seus-negocios.component';
import { TecnologiaNoBrasilConhecaOsPrincipaisEventosDoSegmentoNoPaisComponent } from './pages/our-blog/tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais/tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais.component';
import { DesenvolvedorSaibaComoAumentarASuaProdutividadeComEssasDicasComponent } from './pages/our-blog/desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas/desenvolvedor-saiba-como-aumentar-a-sua-produtividade-com-essas-dicas.component';
import { SaibaQuaisMetricasPodemTeAjudarATerUmAppDeSucessoComponent } from './pages/our-blog/saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso/saiba-quais-metricas-podem-te-ajudar-a-ter-um-app-de-sucesso.component';
import { IndustriaFourZeroComoOsAplicativosContribuemParaEsteProjetoComponent } from './pages/our-blog/industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto/industria-four-zero-como-os-aplicativos-contribuem-para-este-projeto.component';
import { GamificacaoSaibaMaisSobreEssaTendenciaQueEstaDominandoOMercadoComponent } from './pages/our-blog/gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado/gamificacao-saiba-mais-sobre-essa-tendencia-que-esta-dominando-o-mercado.component';
import { SaibaTudoSobreJavascriptComponent } from './pages/our-blog/saiba-tudo-sobre-javascript/saiba-tudo-sobre-javascript.component';
import { VacancyDetailComponent } from './pages/vacancy/vacancy-detail/vacancy-detail.component';
import { HomeOfficeComoOModeloPodeSerVantajosoParaParaOsDesenvolvedoresDeAppComponent } from './pages/our-blog/home-office-como-o-modelo-pode-ser-vantajoso-para-para-os-desenvolvedores-de-app/home-office-como-o-modelo.component';
import { VacancyService } from './service/vacancy.service';
import { FeaturedTwoComponent } from './components/featured-two/featured-two.component';
import { UxOQueEEComoElaPodeTrazerBeneficiosAoSeusNegociosComponent } from './pages/our-blog/ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios/ux-o-que-e-e-como-ela-pode-trazer-beneficios-ao-seus-negocios.component';
import { MetologiaAgilAFormaSimplesDeGerenciarProjetosComplexosComponent } from './pages/our-blog/metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos/metologia-agil-a-forma-simples-de-gerenciar-projetos-complexos.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServicesComponent } from './components/services/services.component';
import { NoBullyingComponent } from './pages/cases/no-bullying/no-bullying.component';
import { SocialFitComponent } from './pages/cases/social-fit/social-fit.component';
import { VicComplianceComponent } from './pages/cases/vic-compliance/vic-compliance.component';
import { SociengeComplianceComponent } from './pages/cases/socienge-compliance/socienge-compliance.component';
import { IntegridadeTotalComponent } from './pages/cases/integridade-total/integridade-total.component';
import { TestisComponent } from './pages/cases/testis/testis.component';
import { BeerTimeClubComponent } from './pages/cases/beer-time-club/beer-time-club.component';
import { LeilaoImoveisBrasilComponent } from './pages/cases/leilao-imoveis-brasil/leilao-imoveis-brasil.component';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TechnologiesComponent,
    FlipComponent,
    NavbarComponent,
    TextLeftImgRightComponent,
    TextRightImgLeftComponent,
    FeaturedComponent,
    PartnerComponent,
    ContactComponent,
    FooterComponent,
    OurServicesComponent,
    AboutUsComponent,
    CasesComponent,
    TeamComponent,
    TimeLineComponent,
    OurContactComponent,
    VacancyComponent,
    PoUiTotvsComponent,
    GameVicEngenhariaComponent,
    ClubePrimeBoxComponent,
    OurBlogComponent,
    SummaryContactsComponent,
    FeedbackComponent,
    BlogComponent,
    EntendaOQueSaoPlataformasLowCodeEAsSuasVantagensComponent,
    ArquiteturaDaInformacaoEntendaOConceitoEComoAplicaLoComponent,
    LinguagemDeProgramacaoOQueEEQuaisSaoMaisUtilizadasComponent,
    OQueFazUmProfissionalFormadoEmDesenvolvimentoDeSistemasComponent,
    GestaoEmTiFourFerramentasQueVaoTeAjudarNosSeusNegociosComponent,
    TecnologiaNoBrasilConhecaOsPrincipaisEventosDoSegmentoNoPaisComponent,
    DesenvolvedorSaibaComoAumentarASuaProdutividadeComEssasDicasComponent,
    SaibaQuaisMetricasPodemTeAjudarATerUmAppDeSucessoComponent,
    IndustriaFourZeroComoOsAplicativosContribuemParaEsteProjetoComponent,
    GamificacaoSaibaMaisSobreEssaTendenciaQueEstaDominandoOMercadoComponent,
    SaibaTudoSobreJavascriptComponent,
    VacancyDetailComponent,
    FeaturedTwoComponent,
    UxOQueEEComoElaPodeTrazerBeneficiosAoSeusNegociosComponent,
    MetologiaAgilAFormaSimplesDeGerenciarProjetosComplexosComponent,
    ServicesComponent,
    NoBullyingComponent,
    SocialFitComponent,
    VicComplianceComponent,
    SociengeComplianceComponent,
    IntegridadeTotalComponent,
    TestisComponent,
    BeerTimeClubComponent,
    LeilaoImoveisBrasilComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxTypedJsModule,
    NgxScrollTopModule,
    CarouselModule,
    FormsModule,
    CountUpModule,
    TooltipModule,
    NgxTypedJsModule,
    NgParticlesModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [VacancyService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
  // export function httpTranslateLoader(http: HttpClient) {
  //   return new TranslateHttpLoader(http);
  // }
