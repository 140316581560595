<div class="page-title-area item-bg-vacancy">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{ vacancy.title | translate }}</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li><a routerLink="/vacancy/">{{'vacancyTextSix' | translate }}</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">{{'vacancyTextSeven' | translate }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-text">
                        <ul class="category">
                            <li><a routerLink="/">{{vacancy.detailsOne | translate}}</a></li>
                            <li><a routerLink="/">{{vacancy.detailsTwo | translate}}</a></li>
                        </ul>
                        ​
                        <ul class="category">
                            <li><a routerLink="/">{{vacancy.detailsThree | translate}}</a></li>
                            <li><a routerLink="/">{{vacancy.detailsFour | translate}}</a></li>
                        </ul>
                        ​
                        <ul class="category">
                            <li><a routerLink="/">{{vacancy.detailsFive | translate}}</a></li>
                            <li><a routerLink="/">{{vacancy.detailsSix | translate}}</a></li>
                        </ul>
                        <h4 class="title">{{vacancy.subTitle | translate}}</h4>
                        <br>
                        <h6>{{vacancy.vacancy | translate}}</h6>

                            <br>
                            <br>
                            <p>{{vacancy.topic | translate}}</p>
                            <p>{{vacancy.subTopicOne | translate}}</p>
                            <p>{{vacancy.subTopicTwo | translate}}</p>
                            <p>{{vacancy.subTopicThree | translate}}</p>
                            <br>
                            <p>{{vacancy.topicTwo | translate}}</p>
                            <p>{{vacancy.subTopicFour | translate}}</p>
                            <p>{{vacancy.subTopicFive | translate}}</p>
                            <p>{{vacancy.subTopicSix | translate}}</p>
                            
                    </div>
                    <div id="contact" class="contact-area ptb-100 bg-F7F5F4">
                        <div class="container">
                            <div class="section-title">
                                <h2>{{'vacancyTextTwentyFour' | translate}}</h2>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <!-- <div class="contact-form">
                                        <div class="container">
                                            <iframe class="responsive-iframe" src="https://its.tolentinos.com/forms/wtl/03d310b4dbe48a247249f466e8d8dafe" height="900" frameborder="0" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div> -->
                                        
                                        
                                        <!--<label for="office">Cargo</label>
                                        <br>
                                        <input type="text" id="office" class="col-lg-12" placeholder="Cargo">
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <label for="name">Nome</label>
                                                <br>
                                                <input type="text" id="name" class="col-lg-12" placeholder="Nome Completo">
                                            </div>
                                            <div class="col-lg-4">
                                                <label for="email">E-mail</label>
                                                <br>
                                                <input type="text" id="email" class="col-lg-12" placeholder="E-mail">
                                            </div>
                                        </div>
                                        <br>
                                        <label for="description">O que devemos saber sobre você?</label>
                                        <br>
                                        <input type="text" id="description" class="col-lg-12" placeholder="O que devemos saber sobre você?">
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <label for="url">LINK Linkedin</label>
                                                <br>
                                                <input type="url" id="url" class="col-lg-12" placeholder="URL Linkedin">
                                            </div>
                                            <div class="col-lg-4">
                                                <label for="salary">Pretenção Salarial</label>
                                                <br>
                                                <input type="number" id="salary" class="col-lg-12" placeholder="R$">
                                            </div>
                                        </div>
                                        <br>
                                        <label>Anexe seu currículo</label>
                                        <br>
                                        <div class="col-lg-6">
                                            <input type="file" id="resume" class="col-lg-12" accept=".pdf, .doc, .docx">
                                        </div>
                                        <br>
                                        <div class="col-lg-12">
                                            <button type="button" class="col-lg-12 btn btn-primary">Enviar</button>
                                        </div>
                                    -->
                                    <!-- </div> -->
                                    <div class="contact-form" style="height: 920px;">
                                        <div class="iframe-container" style="height: 920px;">
                                            <iframe [src]="itsUrl(vacancy.itsUrl)"  frameborder="0" allowfullscreen style="height: 830px;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>