import { Component } from '@angular/core';

@Component({
  selector: 'app-vic-compliance',
  templateUrl: './vic-compliance.component.html',
  styleUrls: ['./vic-compliance.component.scss']
})
export class VicComplianceComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'vic-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/vic-compliance/vic-compliance-1.png'
		},
    {
			link: 'vic-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/vic-compliance/vic-compliance-2.png'
		},
    {
			link: 'vic-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/vic-compliance/vic-compliance-3.png'
		},
    {
			link: 'vic-compliance',
			alt: 'imagesvisual',
			img: 'assets/img/vic-compliance/vic-compliance-4.png'
		}
	];
}
