import { Component } from '@angular/core';

@Component({
  selector: 'app-leilao-imoveis-brasil',
  templateUrl: './leilao-imoveis-brasil.component.html',
  styleUrls: ['./leilao-imoveis-brasil.component.scss']
})
export class LeilaoImoveisBrasilComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'leilao-imoveis-brasil',
			alt: 'imagesvisual',
			img: 'assets/img/leilao-imoveis-brasil/leilao-imoveis-brasil-1.png'
		},
    {
			link: 'leilao-imoveis-brasil',
			alt: 'imagesvisual',
			img: 'assets/img/leilao-imoveis-brasil/leilao-imoveis-brasil-2.png'
		},
    {
			link: 'leilao-imoveis-brasil',
			alt: 'imagesvisual',
			img: 'assets/img/leilao-imoveis-brasil/leilao-imoveis-brasil-3.png'
		},
    {
			link: 'leilao-imoveis-brasil',
			alt: 'imagesvisual',
			img: 'assets/img/leilao-imoveis-brasil/leilao-imoveis-brasil-4.png'
		},
    {
			link: 'leilao-imoveis-brasil',
			alt: 'imagesvisual',
			img: 'assets/img/leilao-imoveis-brasil/leilao-imoveis-brasil-5.png'
		}
	];
}
