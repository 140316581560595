<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>{{'feedbackTextOne' | translate}}</p>
                        <div class="info">
                            <h3>KEITH MENDES</h3>
                            <span>{{'feedbackTextTwo' | translate}}</span>
                            <img src="assets/img/feedback/icon.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>