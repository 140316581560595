import { Component } from '@angular/core';

@Component({
  selector: 'app-po-ui-totvs',
  templateUrl: './po-ui-totvs.component.html',
  styleUrls: ['./po-ui-totvs.component.scss']
})
export class PoUiTotvsComponent {

  listPartners: { link: string, img: string, alt: string }[] = [
		{
			link: 'po-ui-totvs',
			alt: 'imagesvisual',
			img: 'assets/img/po-ui-totvs/po-ui-1.png'
		},
		{
			link: 'po-ui-totvs',
			alt: 'Prefisan',
			img: 'assets/img/po-ui-totvs/po-ui-2.png'
		},
		{
			link: 'po-ui-totvs',
			alt: 'Socienge',
			img: 'assets/img/po-ui-totvs/po-ui-3.png'
		}
	];

}
