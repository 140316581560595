import { Component } from '@angular/core';
import { VacancyService } from 'src/app/service/vacancy.service';

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.scss']
})
export class VacancyComponent {

  timeLine = [ ];

    constructor(public vacancyService: VacancyService) { }

    ngOnInit() {
      this.timeLine = this.vacancyService.vacancysList()
    }
}
