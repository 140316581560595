import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais',
  templateUrl: './tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais.component.html',
  styleUrls: ['./tecnologia-no-brasil-conheca-os-principais-eventos-do-segmento-no-pais.component.scss']
})
export class TecnologiaNoBrasilConhecaOsPrincipaisEventosDoSegmentoNoPaisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}